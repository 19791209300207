<template>
   <n-radio-group :value="value" :name="name" @update:value="$emit('update:value',$event)" class="radiogroup">
      <n-radio-button v-for="item in items" :key="item.value" :value="item.value" :label="item.label" />
   </n-radio-group>
</template>
<script>
   export default {
      name: 'radio-button',
      emits: ["update:value"],
      props: {
         value: {
            type: String,
            default: ''
         },
         items: {
            type: Array,
            default () {
               return []
            }
         },
         name: {
            type: String
         }
      },
      setup() {},
   }
</script>
<style lang="less" scoped>
   .radiogroup {
      :deep(.n-radio-button.n-radio-button--checked) {
         background: #5978FF;
         color: #ffffff;
      }
   }
</style>