<template>
  <div class="floatBar">
    <div class="vx-flex-column">
      <div class="btn">
        <div>
          <img src="@/assets/images/fl01.png" />
        </div>
        <div>客服</div>
      </div>
      <div class="btn">
        <div>
          <img src="@/assets/images/fl02.png" />
        </div>
        <div>微信</div>
      </div>
    </div>
  </div>
</template>
<script>
import { cmd, token } from "vapp2";
import { useRouter } from "vue-router";
export default {
  name: "float-bar",
  emits: ["click"],
  setup() {
    const router = useRouter();
    return {
      goFeedback() {
        console.log("反馈");
        if (token.isAuth.value) {
          router.push("/ucenter/feedback");
        } else {
          cmd.execute("showLogin", null);
        }
      },
    };
  },
};
</script>
<style lang="less" scoped>
.floatBar {
  position: relative;
  width: 66px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(192, 199, 231, 0.4);
  overflow: hidden;

  .btn {
    text-align: center;
    color: #5978ff;
    padding: 15px 0;
    line-height: 1.2;
    cursor: pointer;

    img {
      width: 20px;
      max-height: 20px;
      position: relative;
      transform: translateX(-100px);
      filter: drop-shadow(100px 0 0 #5978ff);
    }

    &.act,
    &:hover {
      background-color: #5978ff;
      color: #ffffff;

      img {
        filter: drop-shadow(100px 0 0 #ffffff);
      }
    }

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }
}
</style>