<template>
   <div class="box homeTable">
      <n-spin :show="isLoading">
         <n-data-table size="small" :columns="columns" :data="data" striped />
         <template #description>加载中</template>
      </n-spin>
      <n-space class="pagination" justify="center">
         <n-button @click="moreData"  :loading="isLoading" v-if="ismore">加载更多</n-button>
      </n-space>
      <phoneDetail ref="detail"></phoneDetail>
   </div>
</template>
<script>
   import { ref, h, reactive, resolveComponent } from 'vue'
   import { updata, db } from 'vapp2'
   import PhoneDetail from './phoneDetail.vue'
   import PhoneNumber from './phoneNumber.vue'
   import CollectBtn from './collectBtn.vue'
   export default {
      name: 'home-search',
      components: { PhoneDetail },
      props: {
         sale: {
            type: Number,
            default: 0
         }
      },
      setup(props, context) {
         const isLoading = ref(false)
         const ismore = ref(true)
         const detail = ref(null)
         const data = ref([])
         const page = reactive({
            currPage: 1,
            totalCount: 0,
            pageSize: 10
         })
         const columns = [
            {
               title: "手机号码",
               key: "phone",
               render(row) {
                  return h(PhoneNumber, {
                     value: row.phone,
                     onClick() {
                        detail.value.open(row)
                     }
                  })
               }
            },
            {
               title: "归属地",
               render(row) {
                  return h('div', row.province + row.city)
               }
            },
            { title: "运营商", key: "service" },
            { title: "售价", key: "money" },
            {
               title: '操作',
               width: 130,
               render(row) {
                  return h(CollectBtn, { info: row })
               }
            }
         ]
         let formData = {}
         let it = null
         let clearIt = function () {
            if (it) {
               clearTimeout(it)
               it = null
               isLoading.value = false
            }
         }
         const loadData = function (top) {
            if (!isLoading.value) {
               isLoading.value = true
               clearIt()
               it = setTimeout(() => {
                  clearIt()
               }, 60000);
               if (top) {
                  page.currPage = 1
               }
               updata.getPhone(Object.assign({ sale: props.sale }, page, formData)).then(res => {
                  if (top) {
                     data.value = res.data.list
                  } else {
                     data.value = [...data.value, ...res.data.list]
                  }
                  ismore.value = (res.data.list.length >= page.pageSize)
                  page.totalCount = res.data.totalCount
                  page.currPage = res.data.currPage
                  clearIt()
               })
            }
         }
         const goPage = function () {
            loadData()
         }
         loadData()
         context.expose({
            loadData(val, top = false) {
               if (val) {
                  let data = {}
                  if (val.keyword) {
                     data.keyword = val.keyword
                  }
                  if (val.service) {
                     data.service = val.service
                  }
                  if (val.rule) {
                     data.rule = val.rule
                  }
                  if (val.area) {
                     data.area = val.area
                  }
                  if (val.phone) {
                     data.phone = val.phone
                  }
                  if (val.price) {
                     data.price = val.price
                  }
                  if (val.order) {
                     data.order = val.order
                  }
                  if (val.unnums) {
                     data.unnums = val.unnums;
                  }
                  if (val.province) {
                     data.province = val.province;
                  }
                  if (val.city) {
                     data.city = val.city;
                  }
                  if (val.birthday) {
                     data.birthday = val.birthday;
                  }
                  if (val.fs) {
                     data.fs = val.fs;
                  }
                  if (val.focus) {
                     let focus = []
                     for (let i = 0; i < 11; i++) {
                        if (val.focus[i] && typeof val.focus[i] != 'undefined') {
                           focus[i] = val.focus[i]
                        } else {
                           focus[i] = '.'
                        }
                     }
                     data.focus = focus.join("")
                  }
                  if (val.lovers) {
                     data.lovers = val.lovers
                  }
                  if (typeof val.sale != 'undefined') {
                     data.sale = val.sale
                  }

                  formData = data
               }
               loadData(top)
            }
         })
         const moreData = function () {
            page.currPage++
            loadData()
         }
         return {
            isLoading,
            detail,
            data,
            columns,
            page,
            goPage,
            moreData,
            ismore,
            updatePageSize(val) {
               page.pageSize = val
               loadData()
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .homeTable {
      position: relative;
      padding: 0;

      .pagination {
         padding: 20px;
      }
   }

   :deep(.n-spin-body) {
      top: 60px;
   }
</style>