<template>
   <div class="notfound">
      <n-empty description="404 找不到页面" size="large"> </n-empty>
   </div>
</template>
<script>
   export default {
      name: 'notfound',
      label: 'notfound'
   }
</script>
<style lang="less" scoped>
   .notfound {
      position: relative;
      background-color: #ffffff;
      padding-top: 100px;
      text-align: center;
      height: 100%;

      :deep(.n-base-icon) {
         color: #0048cf;
      }

      :deep(.n-empty__description) {
         color: #0048cf;
         font-weight: bold;
      }
   }
</style>