<template>
   <div class="collection">
      <Banner></Banner>
      <div class="box pmainContent">
         <CollectTable></CollectTable>
      </div>
   </div>
</template>
<script>
   import Banner from './components/banner.vue'
   import CollectTable from './components/collectTable.vue'
   export default {
      name: "collection",
      components: { Banner, CollectTable },
      setup() {
         return {}
      },
   }
</script>
<style lang="less" scoped>
   .collection {
      .pmainContent {
         padding: 20px 40px;
         margin: 20px auto;

         h3 {
            font-size: 18px;
            margin-bottom: 30px;
         }

         .content {
            max-width: 800px;
            margin: auto;
            padding: 20px;
         }
      }


      .n-card {
         text-align: center;
         max-width: 300px;
         position: relative;
         margin: auto;
      }
   }
</style>