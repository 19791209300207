export default {
   getPhone(data) {
      return {
         url: '/phone',
         data
      }
   },
   getGood(data) {
      return {
         url: '/good',
         data
      }
   },
   editGood(data) {
      return {
         url: '/good/edit',
         data
      }
   },
   delGood(id) {
      return {
         url: '/good/del',
         data: { id }
      }
   },
   getDiy(data) {
      return {
         url: '/diy',
         data
      }
   },
   addDiy(data) {
      return {
         url: '/diy/edit',
         data
      }
   },
   addRecycle(data) {
      return {
         url: '/recycle/edit',
         data
      }
   },
   delRecycle(id) {
      return {
         url: '/recycle/del',
         data: { id }
      }
   },
   getFeedback(data) {
      return {
         url: '/feedback',
         data
      }
   },
   delFeedback(id) {
      return {
         url: '/feedback/del',
         data: { id }
      }
   },
   getInfo(data) {
      return {
         url: '/info',
         data
      }
   },
   editInfo(data) {
      return {
         url: '/info/edit',
         data
      }
   },
   delInfo(id) {
      return {
         url: '/info/del',
         data: { id }
      }
   },
   editPassword(data) {
      return {
         url: '/admin/password',
         data
      }
   }
}