import { ref } from '@vue/reactivity'
export const optionsOrigin = ref([
   { label: "电信", value: '电信' },
   { label: "移动", value: '移动' },
   { label: "联通", value: '联通' },
   { label: "虚商", value: '虚商' }
])
export const optionsRule = [
   { label: "不限", value: '' },
   { label: "尾数AAA", value: 'AAA$' },
   { label: "尾数AAAB", value: 'AAAB$' },
   { label: "尾数AAAAB", value: 'AAAAB$' },
   { label: "尾数ABAB", value: 'ABAB$' },
   { label: "尾数AABB", value: 'AABB$' },
   { label: "尾数ABABAB", value: 'ABABAB$' },
   { label: "尾数AABBCC", value: 'AABBCC$' },
   { label: "尾数ABABCDCD", value: 'ABABCDCD$' },
   { label: "尾数AABBCCDD", value: 'AABBCCDD$' },
   { label: "尾数AAABCCCD", value: 'AAABCCCD$' },
   { label: "尾数ABCD", value: 'ABCD$' },
   { label: "尾数ABCDE", value: 'ABCDE$' },
   { label: "尾数AAABBB", value: 'AAABBB$' },
   { label: "尾数AAAA", value: 'AAAA$' },
   { label: "尾数ABC", value: 'ABC$' },
   { label: "尾数AABBB", value: 'AABBB$' },
   { label: "尾数AAAABB", value: 'AAAABB$' },
   { label: "尾数ABBBCDDD", value: 'ABBBCDDD$' },
   { label: "尾数ABCDABCD", value: 'ABCDABCD$' },
   { label: "尾数ABCDDD", value: 'ABCDDD$' },

   // { label: "尾数AAAAA", value: 'AAAAA$' },
   // { label: "尾数AAAAAA", value: 'AAAAAA$' },
   // { label: "尾数AAAAAAA+", value: 'AAAAAA$' },
   // { label: "尾数AAAAAB", value: 'AAAAAB$' },
   // { label: "尾数AAAAAAB", value: 'AAAAAAB$' },
   // { label: "尾数ABABABAB", value: 'ABABABAB$' },
   // { label: "尾数ABCDEF", value: 'ABCDEF$' },
   // { label: "尾数ABCDEFG+", value: 'ABCDEFG$' },
   // { label: "尾数AAABBBB", value: 'AAABBBB$' },
   // { label: "尾数AAAABBB", value: 'AAAABBB$' },
   // { label: "尾数AABBBB", value: 'AABBBB$' },
   // { label: "尾数AAABB", value: 'AAABB$' },
   // { label: "尾数AAAABBBB", value: 'AAAABBBB$' },
   // { label: "尾数ABCBBB", value: 'ABCBBB$' },
   // { label: "尾数AAAAABB", value: 'AAAAABB$' },
   // { label: "尾数ABCBBBB", value: 'ABCBBBB$' },
   // { label: "尾数AAABBBBB", value: 'AAABBBBB$' },
   // { label: "尾数ABABABBB", value: 'ABABABBB$' },
   // { label: "尾数AABBCCCC", value: 'AABBCCCC$' },
   // { label: "尾数ABCDAABB", value: 'ABCDAABB$' },
   // { label: "尾数ABACADAE", value: 'ABACADAE$' },
   // { label: "尾数ABABCCCC", value: 'ABABCCCC$' },
   // { label: "尾数ABABCCC", value: 'ABABCCC$' },
   // { label: "尾数AABBCCC", value: 'AABBCCC$' },
   // { label: "尾数ABCABC", value: 'ABCABC$' },
   // { label: "尾数AABBABCD", value: 'AABBABCD$' },
   // { label: "尾数CAB", value: 'CAB$' },
   // { label: "尾数DCBA", value: 'DCBA$' },
   // { label: "尾数EDCBA", value: 'EDCBA$' },
   // { label: "尾数FEDCBA", value: 'FEDCBA$' },
   // { label: "尾数GFEDCBA+", value: 'GFEDCBA+$' },
   // { label: "尾数AAAAAABB", value: 'AAAAAABB$' },
   // { label: "尾数AAABAAAB", value: 'AAABAAAB$' },
   // { label: "尾数AAAABBBBB", value: 'AAAABBBBB$' },
   // { label: "尾数AAAAABBBB", value: 'AAAAABBBB$' },
   // { label: "尾数ABBA", value: 'ABBA$' },
   // { label: "尾数ABBABB", value: 'ABBABB$' },
   // { label: "中间AAA", value: 'AAA' },
   // { label: "中间AAAA", value: 'AAAA' },
   // { label: "中间AAAAA", value: 'AAAAA' },
   // { label: "中间AAAAAA", value: 'AAAAAA' },
   // { label: "中间AAAAAAA+", value: 'AAAAAAA' },
   // { label: "中间AAABBB", value: 'AAABBB' },
   // { label: "中间AAABBBB", value: 'AAABBBB' },
   // { label: "中间AAAABBB", value: 'AAAABBB' },
   // { label: "中间ABAB", value: 'ABAB' },
   // { label: "中间AABB", value: 'AABB' },
   // { label: "中间ABABAB", value: 'ABABAB' },
   // { label: "中间ABC", value: 'ABC' },
   // { label: "中间ABCD", value: 'ABCD' },
   // { label: "中间ABCDE", value: 'ABCDE' },
   // { label: "中间ABCDEF", value: 'ABCDEF' },
   // { label: "中间ABCDEFG+", value: 'ABCDEG' },
   // { label: "中间AABBB", value: 'AABBB' },
   // { label: "中间AABBBB", value: 'AABBBB' },
   // { label: "中间AAABB", value: 'AAABB' },
   // { label: "中间AAAABB", value: 'AAAABB' },
   // { label: "中间AAAABBBB", value: 'AAAABBBB' },
   // { label: "中间AABBCC", value: 'AABBCC' },
   // { label: "中间EDCBA", value: 'EDCBA' },
   // { label: "中间FEDCBA", value: 'FEDCBA' }
]
export const optionsOrder = ref([
   { label: '价格低到高', value: 'asc' },
   { label: '价格高到低', value: 'desc' },
   { label: '按更新时间', value: 'time' }
])
export const optionsLovers = ref([
   { label: '我爱你', value: '520' },
   { label: '我想你', value: '530' },
   { label: '爱我吧', value: '258' },
   { label: '抱抱我', value: '885' },
   { label: '就爱你', value: '920' },
   { label: '想想我', value: '335' },
   { label: '长长久久', value: '3399' },
   { label: '就是爱你', value: '9420' },
   { label: '爱你一生', value: '2013' },
   { label: '爱你一世', value: '2014' },
   { label: '生生世世', value: '3344' },
   { label: '我要娶你', value: '5170' },
   { label: '心心相印', value: '7731' },
   { label: '想你一生', value: '3013' },
   { label: '一生一世', value: '1314' },
])
export const checkboxItems = ref([
   { label: '不带0', value: '0' },
   { label: '不带1', value: '1' },
   { label: '不带2', value: '2' },
   { label: '不带3', value: '3' },
   { label: '不带4', value: '4' },
   { label: '不带5', value: '5' },
   { label: '不带6', value: '6' },
   { label: '不带7', value: '7' },
   { label: '不带8', value: '8' },
   { label: '不带9', value: '9' },
])