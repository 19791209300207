<template>
   <div class="business">
      <Banner></Banner>
      <div class="box pmainContent">
         <n-space class="content" justify="center">
            <n-card v-for="item in items" :key="item.id" :title="item.title" class="ncard" hoverable>
               <n-image width="190" :src="network.host+'/../../public/'+item.image" />
               <div> {{item.remark}} </div>
            </n-card>
         </n-space>
      </div>
   </div>
</template>
<script>
   import Banner from './components/banner.vue'
   import { ref } from '@vue/reactivity'
   import { updata, config } from 'vapp2'
   export default {
      name: "business",
      components: { Banner },
      setup() {

         const items = ref([])
         updata.getInfo({ type: '3' }).then(res => {
            items.value = res.data
         })
         return {
            items,
            network: config.network
         }
      },
   }
</script>
<style lang="less" scoped>
   .business {
      .pmainContent {
         padding: 20px 40px;
         margin: 20px auto;
         text-align: center;
         min-height: 500px;

         h3 {
            font-size: 18px;
            margin-bottom: 30px;
         }

         .content {
            margin: auto;
            padding: 20px;
         }
      }


      .n-card {
         text-align: center;
         max-width: 300px;
         position: relative;
         margin: auto;
      }
   }
</style>