<template>
   <div class="headerLogo"></div>
</template>
<script>
   export default {
      name: 'header-logo',
      setup() {
         return {}
      },
   }
</script>
<style lang="less" scoped>
   .headerLogo {
      position: relative;
      margin-top: 17px;
      display: inline-block;
      width: 175px;
      background-image: url("@/assets/logo.png");
      background-size: 100%;
      background-repeat: no-repeat;
   }
</style>