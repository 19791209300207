<template>
   <div class="sale">
      <Banner></Banner>
      <div class="homeMain">
         <HomeFilter @click-search="search"></HomeFilter>
         <HomeTable ref="reftable" :sale="1"></HomeTable>
      </div>
   </div>
</template>
<script>
   import HomeSearch from './components/homeSearch.vue'
   import HomeFilter from './components/homeFilter.vue'
   import HomeTable from './components/homeTable.vue'
   import Banner from './components/banner.vue'
   import { ref } from '@vue/reactivity'
   export default {
      name: 'sale',
      label: '首页',
      components: { HomeSearch, HomeFilter, HomeTable, Banner },
      setup() {
         const reftable = ref(null)
         const search = function (val) {
            if (reftable.value) {
               reftable.value.loadData(Object.assign({}, val, { sale: 1 }), true)
            }
         }
         return { reftable, search }
      }
   }
</script>
<style lang="less" scoped>
   .sale {
      position: relative;
      margin: auto;
      height: 100%;

      .homeMain {
         position: relative;
         margin: auto;
         max-width: 1280px;
      }

      .between {
         justify-content: space-between;
      }


   }
</style>