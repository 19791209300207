import { cmd } from 'vapp2'

export const cmdmessage = function () {
   cmd.execute('message', ...arguments)
}


export const handleTreeMapData = function (items, nameKey, valueKey) {
   let children = []
   if (Array.isArray(items)) {
      return children = items.map(item => {
         let element = {
            name: item[nameKey],
            value: item[valueKey]
         }
         if (item.children) {
            element.children = handleTreeMapData(item.children, nameKey, valueKey)
         }
         return element
      })
   } else {
      return []
   }
}
export const scollItemsLoop = function (domId, fold = false) {

   let items = document.querySelector("#" + domId);
   if (items) {
      let children = items.children
      let lg = children.length
      if (items.clientHeight > items.parentElement.clientHeight) {
         for (var i = 0; i < lg; i++)
         {
            let em = children[i].cloneNode(true);
            items.appendChild(em)
         }
      }

      if (fold) {
         for (var i = 0; i < children.length; i++)
         {
            children[i].id = domId + "_" + i
            children[i].querySelector('.title').addEventListener('click', evt => {
               let des = evt.target.parentNode.querySelector('.des')
               if (des.style.display) {
                  des.style.display = ''
               } else {
                  des.style.display = 'block'
               }
            })
         }
      }
      // 滚动
      if (items.clientHeight > items.parentElement.clientHeight) {
         items.style.animation = (items.clientHeight / 55.4) + "s rowup linear infinite normal"
         items.style.msAnimation = (items.clientHeight / 55.4) + "s rowup linear infinite normal"
      }
   }
}
window.cmdmessage = cmdmessage