<template>
   <n-button @click="collect">{{iscollect?'取消收藏':'收藏'}}</n-button>
</template>
<script>
   import { ref, toRef, toRefs } from '@vue/reactivity'
   import { db } from 'vapp2'
   import { watch } from '@vue/runtime-core'
   export default {
      name: 'collect-btn',
      emits: ['click'],
      props: {
         info: {
            type: Object,
            default () {
               return {}
            }
         }
      },
      setup(props, context) {
         const iscollect = ref(false)
         const { info } = toRefs(props)

         watch(info, newInfo => {
            if (newInfo.id && db.collect.phone) {
               if (db.collect.phone.find(item => item.id == newInfo.id)) {
                  iscollect.value = true
               } else {
                  iscollect.value = false
               }
            }
         }, { immediate: true, deep: true })
         return {
            iscollect,
            collect() {
               if (props.info.id && db.collect.phone) {
                  let index = db.collect.phone.findIndex(item => item.id == props.info.id)
                  if (iscollect.value) {
                     if (index > -1) {
                        db.collect.phone.splice(index, 1)
                     }
                     iscollect.value = false
                  } else {
                     if (index < 0) {
                        db.collect.phone.push(props.info)
                     }
                     iscollect.value = true
                  }
                  context.emit('click', JSON.parse(JSON.stringify(db.collect.phone)))
               }
            }
         }
      },
   }
</script>