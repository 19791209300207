<template>
   <n-modal v-model:show="show" transform-origin="center" to="#app" title="号码详情" preset="card" :style="bodyStyle" :mask-closable="true" bordered>
      <n-descriptions label-placement="left" :label-style="{width:'110px'}" :column="2" bordered>
         <n-descriptions-item label="手机号码" :span="2">{{item.phone}} </n-descriptions-item>
         <n-descriptions-item label="服务费"> {{item.income}} 元</n-descriptions-item>
         <n-descriptions-item label="代收预存款"> {{item.prestore}} 元 </n-descriptions-item>
         <n-descriptions-item label="归属地" :span="2"> {{item.province+item.city}} </n-descriptions-item>
         <n-descriptions-item label="运营商" :span="2"> {{item.service}} </n-descriptions-item>
         <n-descriptions-item label="套餐详情" :span="2"> {{item.detail}} </n-descriptions-item>
      </n-descriptions>
      <div class="des">代收预存款说明：这部分预存款本公司代收，最终会以话费形式充入到购买的号码中</div>
   </n-modal>
</template>
<script>
   import { ref } from '@vue/reactivity'
   export default {
      name: 'phone-deatil',
      setup(props, context) {
         const show = ref(false)
         const item = ref({})
         context.expose({
            open(info) {
               item.value = info
               show.value = true
            }
         })
         return {
            show,
            item,
            bodyStyle: {
               width: '500px',
               height: '600px',
               background: '#FFFFFF',
               borderRadius: '8px',
               boxShadow: '0px 5px 5px 0px rgba(0, 21, 41, 0.08)'
            },
         }
      },
   }
</script>
<style lang="less" scoped>
   .des {
      padding: 20px 0;
      color: red;
   }
</style>