<template>
   <div class="box homeFilter">
      <n-form ref="formRef" label-placement="left" label-width="auto" inline :model="formData" :rules="rules">
         <n-grid :cols="24" :x-gap="24">
            <n-form-item-gi :span="4" label="运营商">
               <n-select v-model:value="formData.service" clearable :options="optionsOrigin" />
            </n-form-item-gi>
            <n-form-item-gi :span="7" label="归属地">
               <n-space>
                  <n-select v-model:value="formData.province" @update:value="formData.city=null" clearable :options="optionsProvince" />
                  <n-select v-model:value="formData.city" clearable :options="optionsCity" />
               </n-space>
            </n-form-item-gi>
            <n-form-item-gi :span="5" label="号码规律">
               <n-select :style="{width:'180px'}" v-model:value="formData.rule" clearable :virtual-scroll="false" :options="optionsRule" />
            </n-form-item-gi>
            <n-form-item-gi :span="4" label="生日靓号">
               <n-date-picker v-model:formatted-value="formData.birthday" value-format="yyyyMMdd" format="yyMMdd" clearable />
            </n-form-item-gi>
            <n-form-item-gi :span="4" label="情侣号码">
               <n-select v-model:value="formData.lovers" clearable :options="optionsLovers" />
            </n-form-item-gi>
            <n-form-item-gi :span="4" label="排序方式">
               <n-select v-model:value="formData.order" clearable :options="optionsOrder" />
            </n-form-item-gi>
            <n-form-item-gi :span="12" label="精准搜索">
               <n-space justify="space-between">
                  <n-input :value="formData.focus[0]" @input="updateFocus($event,0)" :ref="(el) => refFocus[0] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[1]" @input="updateFocus($event,1)" :ref="(el) => refFocus[1] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[2]" @input="updateFocus($event,2)" :ref="(el) => refFocus[2] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[3]" @input="updateFocus($event,3)" :ref="(el) => refFocus[3] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[4]" @input="updateFocus($event,4)" :ref="(el) => refFocus[4] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[5]" @input="updateFocus($event,5)" :ref="(el) => refFocus[5] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[6]" @input="updateFocus($event,6)" :ref="(el) => refFocus[6] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[7]" @input="updateFocus($event,7)" :ref="(el) => refFocus[7] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[8]" @input="updateFocus($event,8)" :ref="(el) => refFocus[8] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[9]" @input="updateFocus($event,9)" :ref="(el) => refFocus[9] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
                  <n-input :value="formData.focus[10]" @input="updateFocus($event,10)" :ref="(el) => refFocus[10] = el" :show-button="false" :min="0" :max="99" placeholder="" class="number" />
               </n-space>
            </n-form-item-gi>
            <n-form-item-gi :span="8" label="价格区间">
               <n-space>
                  <n-input-number v-model:value="formData.price[0]" :show-button="false" placeholder="" class="number2">
                     <template #prefix> ￥ </template>
                  </n-input-number>
                  <div> - </div>
                  <n-input-number v-model:value="formData.price[1]" :show-button="false" placeholder="" class="number2">
                     <template #prefix> ￥ </template>
                  </n-input-number>
               </n-space>
            </n-form-item-gi>
            <n-form-item-gi :span="21" label="数字属性">
               <n-checkbox-group v-model:value="formData.unnums">
                  <n-checkbox v-for="(item) in checkboxItems" :key="item.value" :value="item.value" :label="item.label"></n-checkbox>
               </n-checkbox-group>
               <n-checkbox v-model:checked="formData.fs" label="风水号"></n-checkbox>
            </n-form-item-gi>
            <n-form-item-gi :span="3" label="">
               <n-space justify="end">
                  <n-button type="info" @click="search">查询</n-button>
                  <n-button type="warning" @click="reset">重置</n-button>
               </n-space>
            </n-form-item-gi>
         </n-grid>
      </n-form>
   </div>
</template>
<script>
   import { reactive, ref } from '@vue/reactivity'
   import { computed } from '@vue/runtime-core'
   import { default as city, getOptions } from '@/data/city'
   import { optionsOrigin, optionsRule, optionsOrder, optionsLovers, checkboxItems } from './options'
   export default {
      name: 'home-filter',
      emits: ['click-search'],
      setup(props, context) {
         const formData = reactive({
            service: null,
            rule: null,
            birthday: null,
            lovers: null,
            phone: null,
            focus: [null, null, null, null, null, null, null, null, null, null, null],
            price: [null, null],
            order: null,
            unnums: null,
            province: null,
            city: null,
            fs: null
         })
         const refFocus = reactive([null, null, null, null, null, null, null, null, null, null, null])
         const rules = reactive([])
         const optionsProvince = getOptions(city)
         const optionsCity = computed(() => {
            if (formData.province) {
               let index = optionsProvince.findIndex(item => item.value == formData.province)
               if (index > -1) {
                  return getOptions(city[index].children)
               } else {
                  return []
               }
            } else {
               return []
            }
         })

         const search = function () {
            let data = JSON.parse(JSON.stringify(formData))
            context.emit('click-search', data)
         }
         const updateFocus = function (val, key) {
            let num = parseInt(val)
            if ((num && num < 10) || val == '' || num == 0) {
               formData.focus[key] = val
               if (refFocus[key + 1] && val != '') {
                  refFocus[key + 1].focus()
               }
            }
         }
         const reset = function () {
            Object.assign(formData, {
               service: null,
               province: null,
               city: null,
               rule: null,
               birthday: null,
               lovers: null,
               phone: null,
               focus: [null, null, null, null, null, null, null, null, null, null, null],
               price: [null, null],
               order: null,
               unnums: null,
               fs: null
            })
            search()
         }

         return {
            formData,
            refFocus,
            updateFocus,
            rules,
            optionsOrigin,
            optionsProvince,
            optionsCity,
            checkboxItems,
            optionsRule,
            optionsOrder,
            optionsLovers,
            search,
            reset
         }
      },
   }
</script>
<style lang="less" scoped>
   .homeFilter {
      position: relative;
      width: 100%;
      margin: 20px auto;
      padding: 20px;

      .n-select {
         width: 130px;
      }

      .number {
         width: 35px;
         text-align: center;
      }

      .number2 {
         width: 145px;
      }
   }
</style>