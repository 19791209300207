<template>
   <n-config-provider :locale="zhCN" :date-locale="dateZhCN" :theme-overrides="theme">
      <n-message-provider>
         <n-dialog-provider>
            <div class="vx-flex-column appMain" :class="{loading:!enter}">
               <AppHeader />
               <AppContent />
               <AppFooter />
            </div>
         </n-dialog-provider>
      </n-message-provider>
   </n-config-provider>
</template>
<script>
   import { ref } from '@vue/reactivity'
   import { zhCN, dateZhCN } from 'naive-ui'
   import { theme } from './theme'
   import { initData } from '@/data'
   import AppContent from './element/content.vue'
   import AppHeader from './element/header.vue'
   import AppFooter from './element/footer.vue'
   export default {
      name: 'app-main',
      components: { AppContent, AppHeader, AppFooter },
      setup() {
         const enter = ref(true)
         initData()
         return { zhCN, dateZhCN, theme, enter }
      }
   }
</script>
<style lang="less" scoped>
   .appMain {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
   }
</style>