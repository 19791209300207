<template>
   <!-- 注册-弹窗   -->
   <n-modal v-model:show="show" transform-origin="center" to="#app" preset="card" :style="bodyStyle" :mask-closable="true" title="欢迎使用" @after-leave="afterLeave" bordered>
      欢迎使用
   </n-modal>
</template>
<script>
   import { ref, defineComponent } from "vue"
   import { cmd, updata } from 'vapp2'
   import { cmdmessage } from '@/common'

   export default defineComponent({
      name: 'show-register',
      setup(props, context) {
         // 是否显示
         const show = ref(false)
         let callBack = null
         const preSData = ref({ type: 'loginP0', phone: '' })
         // 打开窗口
         const open = function (val, _callBack) {
            preSData.value = val;
            show.value = true
            callBack = _callBack
            console.log('注册页---item')
            console.log(val)
         }
         cmd.add('showRegister', open)

         const afterLeave = function () {
            callBack && callBack()
         }

         const closeView = function () {
            show.value = false;
         }
         return {
            show,
            closeView,
            preSData,
            afterLeave,
            bodyStyle: {
               width: '500px', //600  
               height: '769px', //769
               background: '#FFFFFF',
               borderRadius: '8px',
               boxShadow: '0px 5px 5px 0px rgba(0, 21, 41, 0.08)'
            },
         }
      }
   })
</script>

<style lang="less" scoped>
   .assignList {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: var(--rd-content-bg-color);
      overflow: hidden;
   }

   .leftContain {
      position: relative;
      width: 290px;
      height: 100%;
   }

   .title {
      position: relative;
      margin-top: 0px;
      width: 100%; //438
      height: 17px;
      font-size: 16px;
      // padding-left: 30px;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
   }

   .info_label {
      position: relative;
      margin-top: 20px;
      height: 30px;
      padding-left: 5px;

      .label_pre {
         width: 100%; //42
         height: 14px;
         font-size: 14px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #666666;
      }

      .name {
         width: 120px;
         height: 15px;
         font-size: 14px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #333333;
      }

      .time {
         position: relative;
         width: 237px; //137
         height: 12px;
         font-size: 14px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #999999;
         text-align: right;
      }
   }

   .content {
      position: relative;
      // margin-top: 30px;
      // margin-left: 30px;
      // scroll:y;
      // overflow-x:auto;
      overflow: auto;
      margin-top: 10px;
      // width: 505px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding-bottom: 10px;
      width: 100%;
      height: 322px; //297
      max-height: 322px; //432  342
      word-wrap: break-word;
      word-break: break-all;
      padding: 5px;
      padding-left: 15px;

      border: 1px solid #E5E5E5;
      border-radius: 4px;
   }

   .read {
      position: relative;
      margin-top: 15px;
      height: 20px;
      float: right;

      .readName {
         float: right;
         text-align: right;
      }

      .label_pre {
         width: 100%; //42
         height: 14px;
         font-size: 14px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #666666;
         text-align: right;
      }

      .name {
         width: 120px;
         height: 15px;
         font-size: 14px;
         font-family: Microsoft YaHei;
         font-weight: 400;

         color: #333333;
      }
   }

   .rolecontain {
      position: relative;
      margin-left: 0px;
      margin-top: 10px;
      width: 260px;
      overflow: hidden;
      height: 446px;
   }

   .modalLX {
      position: relative;
      margin-left: 0px;
      margin-top: 0px;
      width: 100%;
      height: 50px; //59+39  
      font-size: 28px; //32
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #4E6AE7;
      line-height: 50px;
      padding-left: 0px;

      // font-size: 32px;
      // font-family: Source Han Sans SC;
      // font-weight: 800;
      // color: #4E6AE7;

   }
</style>