import { h, resolveComponent } from 'vue'
import defImg from '@/assets/images/image.png'
import {
   NImage
} from 'naive-ui'
export default {
   name: 'rxp-image',
   components: { NImage },
   setup(props) {
      return () => {
         let _props = Object.assign({ fallbackSrc: defImg }, props)
         return h(resolveComponent('n-image'), _props)
      }
   }
}