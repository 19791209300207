<template>
   <div class="pnumber">
      <span class="num" v-for="(val,i) in items" :key="i">{{val}}</span>
   </div>
</template>
<script>
   import { computed } from '@vue/runtime-core'
   export default {
      name: 'phone-number',
      props: {
         value: {
            type: String,
            default: ''
         }
      },
      setup(props) {
         const items = computed(() => {
            if (props.value) {
               return [props.value.slice(0, 3), props.value.slice(3, 7), props.value.slice(7)]
            } else {
               return []
            }
         })
         return { items }
      },
   }
</script>
<style lang="less" scoped>
   .pnumber {
      cursor: pointer;

      .num {
         color: rgb(121, 121, 121);

         &:nth-child(2) {
            color: rgb(255, 0, 0);
         }

         &:nth-child(3) {
            color: rgb(0, 140, 255);
         }
      }
   }
</style>