export const replaceText = function (text) {
   return text.replace(/(省|市|自治区|维吾尔自治区|壮族自治区|回族自治区|藏族自治区|维吾尔自治州|壮族自治州|回族自治州|藏族自治州)/, '')
}
export const getOptions = function (items) {
   return items.map((item, index) => {
      let label = replaceText(item.label)
      return {
         label,
         index,
         value: label
      }
   })
}
export default [
{
   "id": "MTEwMDAw",
   "label": "北京市",
   "value": "110000",
   "class": "省",
   "children": [
   {
      "id": "MTEwMTAw",
      "label": "北京市",
      "value": "110100",
      "class": "市"
   }]
},
{
   "id": "MTIwMDAw",
   "label": "天津市",
   "value": "120000",
   "class": "省",
   "children": [
   {
      "id": "MTIwMTAw",
      "label": "天津市",
      "value": "120100",
      "class": "市"
   }]
},
{
   "id": "MTMwMDAw",
   "label": "河北省",
   "value": "130000",
   "class": "省",
   "children": [
   {
      "id": "MTMwMTAw",
      "label": "石家庄市",
      "value": "130100",
      "class": "市"
   },
   {
      "id": "MTMwMjAw",
      "label": "唐山市",
      "value": "130200",
      "class": "市"
   },
   {
      "id": "MTMwMzAw",
      "label": "秦皇岛市",
      "value": "130300",
      "class": "市"
   },
   {
      "id": "MTMwNDAw",
      "label": "邯郸市",
      "value": "130400",
      "class": "市"
   },
   {
      "id": "MTMwNTAw",
      "label": "邢台市",
      "value": "130500",
      "class": "市"
   },
   {
      "id": "MTMwNjAw",
      "label": "保定市",
      "value": "130600",
      "class": "市"
   },
   {
      "id": "MTMwNzAw",
      "label": "张家口市",
      "value": "130700",
      "class": "市"
   },
   {
      "id": "MTMwODAw",
      "label": "承德市",
      "value": "130800",
      "class": "市"
   },
   {
      "id": "MTMwOTAw",
      "label": "沧州市",
      "value": "130900",
      "class": "市"
   },
   {
      "id": "MTMxMDAw",
      "label": "廊坊市",
      "value": "131000",
      "class": "市"
   },
   {
      "id": "MTMxMTAw",
      "label": "衡水市",
      "value": "131100",
      "class": "市"
   }]
},
{
   "id": "MTQwMDAw",
   "label": "山西省",
   "value": "140000",
   "class": "省",
   "children": [
   {
      "id": "MTQwMTAw",
      "label": "太原市",
      "value": "140100",
      "class": "市"
   },
   {
      "id": "MTQwMjAw",
      "label": "大同市",
      "value": "140200",
      "class": "市"
   },
   {
      "id": "MTQwMzAw",
      "label": "阳泉市",
      "value": "140300",
      "class": "市"
   },
   {
      "id": "MTQwNDAw",
      "label": "长治市",
      "value": "140400",
      "class": "市"
   },
   {
      "id": "MTQwNTAw",
      "label": "晋城市",
      "value": "140500",
      "class": "市"
   },
   {
      "id": "MTQwNjAw",
      "label": "朔州市",
      "value": "140600",
      "class": "市"
   },
   {
      "id": "MTQwNzAw",
      "label": "晋中市",
      "value": "140700",
      "class": "市"
   },
   {
      "id": "MTQwODAw",
      "label": "运城市",
      "value": "140800",
      "class": "市"
   },
   {
      "id": "MTQwOTAw",
      "label": "忻州市",
      "value": "140900",
      "class": "市"
   },
   {
      "id": "MTQxMDAw",
      "label": "临汾市",
      "value": "141000",
      "class": "市"
   },
   {
      "id": "MTQxMTAw",
      "label": "吕梁市",
      "value": "141100",
      "class": "市"
   }]
},
{
   "id": "MTUwMDAw",
   "label": "内蒙古自治区",
   "value": "150000",
   "class": "省",
   "children": [
   {
      "id": "MTUwMTAw",
      "label": "呼和浩特市",
      "value": "150100",
      "class": "市"
   },
   {
      "id": "MTUwMjAw",
      "label": "包头市",
      "value": "150200",
      "class": "市"
   },
   {
      "id": "MTUwMzAw",
      "label": "乌海市",
      "value": "150300",
      "class": "市"
   },
   {
      "id": "MTUwNDAw",
      "label": "赤峰市",
      "value": "150400",
      "class": "市"
   },
   {
      "id": "MTUwNTAw",
      "label": "通辽市",
      "value": "150500",
      "class": "市"
   },
   {
      "id": "MTUwNjAw",
      "label": "鄂尔多斯市",
      "value": "150600",
      "class": "市"
   },
   {
      "id": "MTUwNzAw",
      "label": "呼伦贝尔市",
      "value": "150700",
      "class": "市"
   },
   {
      "id": "MTUwODAw",
      "label": "巴彦淖尔市",
      "value": "150800",
      "class": "市"
   },
   {
      "id": "MTUwOTAw",
      "label": "乌兰察布市",
      "value": "150900",
      "class": "市"
   },
   {
      "id": "MTUyMjAw",
      "label": "兴安盟",
      "value": "152200",
      "class": "市"
   },
   {
      "id": "MTUyNTAw",
      "label": "锡林郭勒盟",
      "value": "152500",
      "class": "市"
   },
   {
      "id": "MTUyOTAw",
      "label": "阿拉善盟",
      "value": "152900",
      "class": "市"
   }]
},
{
   "id": "MjEwMDAw",
   "label": "辽宁省",
   "value": "210000",
   "class": "省",
   "children": [
   {
      "id": "MjEwMTAw",
      "label": "沈阳市",
      "value": "210100",
      "class": "市"
   },
   {
      "id": "MjEwMjAw",
      "label": "大连市",
      "value": "210200",
      "class": "市"
   },
   {
      "id": "MjEwMzAw",
      "label": "鞍山市",
      "value": "210300",
      "class": "市"
   },
   {
      "id": "MjEwNDAw",
      "label": "抚顺市",
      "value": "210400",
      "class": "市"
   },
   {
      "id": "MjEwNTAw",
      "label": "本溪市",
      "value": "210500",
      "class": "市"
   },
   {
      "id": "MjEwNjAw",
      "label": "丹东市",
      "value": "210600",
      "class": "市"
   },
   {
      "id": "MjEwNzAw",
      "label": "锦州市",
      "value": "210700",
      "class": "市"
   },
   {
      "id": "MjEwODAw",
      "label": "营口市",
      "value": "210800",
      "class": "市"
   },
   {
      "id": "MjEwOTAw",
      "label": "阜新市",
      "value": "210900",
      "class": "市"
   },
   {
      "id": "MjExMDAw",
      "label": "辽阳市",
      "value": "211000",
      "class": "市"
   },
   {
      "id": "MjExMTAw",
      "label": "盘锦市",
      "value": "211100",
      "class": "市"
   },
   {
      "id": "MjExMjAw",
      "label": "铁岭市",
      "value": "211200",
      "class": "市"
   },
   {
      "id": "MjExMzAw",
      "label": "朝阳市",
      "value": "211300",
      "class": "市"
   },
   {
      "id": "MjExNDAw",
      "label": "葫芦岛市",
      "value": "211400",
      "class": "市"
   }]
},
{
   "id": "MjIwMDAw",
   "label": "吉林省",
   "value": "220000",
   "class": "省",
   "children": [
   {
      "id": "MjIwMTAw",
      "label": "长春市",
      "value": "220100",
      "class": "市"
   },
   {
      "id": "MjIwMjAw",
      "label": "吉林市",
      "value": "220200",
      "class": "市"
   },
   {
      "id": "MjIwMzAw",
      "label": "四平市",
      "value": "220300",
      "class": "市"
   },
   {
      "id": "MjIwNDAw",
      "label": "辽源市",
      "value": "220400",
      "class": "市"
   },
   {
      "id": "MjIwNTAw",
      "label": "通化市",
      "value": "220500",
      "class": "市"
   },
   {
      "id": "MjIwNjAw",
      "label": "白山市",
      "value": "220600",
      "class": "市"
   },
   {
      "id": "MjIwNzAw",
      "label": "松原市",
      "value": "220700",
      "class": "市"
   },
   {
      "id": "MjIwODAw",
      "label": "白城市",
      "value": "220800",
      "class": "市"
   },
   {
      "id": "MjIyNDAw",
      "label": "延边朝鲜族自治州",
      "value": "222400",
      "class": "市"
   }]
},
{
   "id": "MjMwMDAw",
   "label": "黑龙江省",
   "value": "230000",
   "class": "省",
   "children": [
   {
      "id": "MjMwMTAw",
      "label": "哈尔滨市",
      "value": "230100",
      "class": "市"
   },
   {
      "id": "MjMwMjAw",
      "label": "齐齐哈尔市",
      "value": "230200",
      "class": "市"
   },
   {
      "id": "MjMwMzAw",
      "label": "鸡西市",
      "value": "230300",
      "class": "市"
   },
   {
      "id": "MjMwNDAw",
      "label": "鹤岗市",
      "value": "230400",
      "class": "市"
   },
   {
      "id": "MjMwNTAw",
      "label": "双鸭山市",
      "value": "230500",
      "class": "市"
   },
   {
      "id": "MjMwNjAw",
      "label": "大庆市",
      "value": "230600",
      "class": "市"
   },
   {
      "id": "MjMwNzAw",
      "label": "伊春市",
      "value": "230700",
      "class": "市"
   },
   {
      "id": "MjMwODAw",
      "label": "佳木斯市",
      "value": "230800",
      "class": "市"
   },
   {
      "id": "MjMwOTAw",
      "label": "七台河市",
      "value": "230900",
      "class": "市"
   },
   {
      "id": "MjMxMDAw",
      "label": "牡丹江市",
      "value": "231000",
      "class": "市"
   },
   {
      "id": "MjMxMTAw",
      "label": "黑河市",
      "value": "231100",
      "class": "市"
   },
   {
      "id": "MjMxMjAw",
      "label": "绥化市",
      "value": "231200",
      "class": "市"
   },
   {
      "id": "MjMyNzAw",
      "label": "大兴安岭地区",
      "value": "232700",
      "class": "市"
   }]
},
{
   "id": "MzEwMDAw",
   "label": "上海市",
   "value": "310000",
   "class": "省",
   "children": [
   {
      "id": "MzEwMTAw",
      "label": "上海市",
      "value": "310100",
      "class": "市"
   }]
},
{
   "id": "MzIwMDAw",
   "label": "江苏省",
   "value": "320000",
   "class": "省",
   "children": [
   {
      "id": "MzIwMTAw",
      "label": "南京市",
      "value": "320100",
      "class": "市"
   },
   {
      "id": "MzIwMjAw",
      "label": "无锡市",
      "value": "320200",
      "class": "市"
   },
   {
      "id": "MzIwMzAw",
      "label": "徐州市",
      "value": "320300",
      "class": "市"
   },
   {
      "id": "MzIwNDAw",
      "label": "常州市",
      "value": "320400",
      "class": "市"
   },
   {
      "id": "MzIwNTAw",
      "label": "苏州市",
      "value": "320500",
      "class": "市"
   },
   {
      "id": "MzIwNjAw",
      "label": "南通市",
      "value": "320600",
      "class": "市"
   },
   {
      "id": "MzIwNzAw",
      "label": "连云港市",
      "value": "320700",
      "class": "市"
   },
   {
      "id": "MzIwODAw",
      "label": "淮安市",
      "value": "320800",
      "class": "市"
   },
   {
      "id": "MzIwOTAw",
      "label": "盐城市",
      "value": "320900",
      "class": "市"
   },
   {
      "id": "MzIxMDAw",
      "label": "扬州市",
      "value": "321000",
      "class": "市"
   },
   {
      "id": "MzIxMTAw",
      "label": "镇江市",
      "value": "321100",
      "class": "市"
   },
   {
      "id": "MzIxMjAw",
      "label": "泰州市",
      "value": "321200",
      "class": "市"
   },
   {
      "id": "MzIxMzAw",
      "label": "宿迁市",
      "value": "321300",
      "class": "市"
   }]
},
{
   "id": "MzMwMDAw",
   "label": "浙江省",
   "value": "330000",
   "class": "省",
   "children": [
   {
      "id": "MzMwMTAw",
      "label": "杭州市",
      "value": "330100",
      "class": "市"
   },
   {
      "id": "MzMwMjAw",
      "label": "宁波市",
      "value": "330200",
      "class": "市"
   },
   {
      "id": "MzMwMzAw",
      "label": "温州市",
      "value": "330300",
      "class": "市"
   },
   {
      "id": "MzMwNDAw",
      "label": "嘉兴市",
      "value": "330400",
      "class": "市"
   },
   {
      "id": "MzMwNTAw",
      "label": "湖州市",
      "value": "330500",
      "class": "市"
   },
   {
      "id": "MzMwNjAw",
      "label": "绍兴市",
      "value": "330600",
      "class": "市"
   },
   {
      "id": "MzMwNzAw",
      "label": "金华市",
      "value": "330700",
      "class": "市"
   },
   {
      "id": "MzMwODAw",
      "label": "衢州市",
      "value": "330800",
      "class": "市"
   },
   {
      "id": "MzMwOTAw",
      "label": "舟山市",
      "value": "330900",
      "class": "市"
   },
   {
      "id": "MzMxMDAw",
      "label": "台州市",
      "value": "331000",
      "class": "市"
   },
   {
      "id": "MzMxMTAw",
      "label": "丽水市",
      "value": "331100",
      "class": "市"
   }]
},
{
   "id": "MzQwMDAw",
   "label": "安徽省",
   "value": "340000",
   "class": "省",
   "children": [
   {
      "id": "MzQwMTAw",
      "label": "合肥市",
      "value": "340100",
      "class": "市"
   },
   {
      "id": "MzQwMjAw",
      "label": "芜湖市",
      "value": "340200",
      "class": "市"
   },
   {
      "id": "MzQwMzAw",
      "label": "蚌埠市",
      "value": "340300",
      "class": "市"
   },
   {
      "id": "MzQwNDAw",
      "label": "淮南市",
      "value": "340400",
      "class": "市"
   },
   {
      "id": "MzQwNTAw",
      "label": "马鞍山市",
      "value": "340500",
      "class": "市"
   },
   {
      "id": "MzQwNjAw",
      "label": "淮北市",
      "value": "340600",
      "class": "市"
   },
   {
      "id": "MzQwNzAw",
      "label": "铜陵市",
      "value": "340700",
      "class": "市"
   },
   {
      "id": "MzQwODAw",
      "label": "安庆市",
      "value": "340800",
      "class": "市"
   },
   {
      "id": "MzQxMDAw",
      "label": "黄山市",
      "value": "341000",
      "class": "市"
   },
   {
      "id": "MzQxMTAw",
      "label": "滁州市",
      "value": "341100",
      "class": "市"
   },
   {
      "id": "MzQxMjAw",
      "label": "阜阳市",
      "value": "341200",
      "class": "市"
   },
   {
      "id": "MzQxMzAw",
      "label": "宿州市",
      "value": "341300",
      "class": "市"
   },
   {
      "id": "MzQxNTAw",
      "label": "六安市",
      "value": "341500",
      "class": "市"
   },
   {
      "id": "MzQxNjAw",
      "label": "亳州市",
      "value": "341600",
      "class": "市"
   },
   {
      "id": "MzQxNzAw",
      "label": "池州市",
      "value": "341700",
      "class": "市"
   },
   {
      "id": "MzQxODAw",
      "label": "宣城市",
      "value": "341800",
      "class": "市"
   }]
},
{
   "id": "MzUwMDAw",
   "label": "福建省",
   "value": "350000",
   "class": "省",
   "children": [
   {
      "id": "MzUwMTAw",
      "label": "福州市",
      "value": "350100",
      "class": "市"
   },
   {
      "id": "MzUwMjAw",
      "label": "厦门市",
      "value": "350200",
      "class": "市"
   },
   {
      "id": "MzUwMzAw",
      "label": "莆田市",
      "value": "350300",
      "class": "市"
   },
   {
      "id": "MzUwNDAw",
      "label": "三明市",
      "value": "350400",
      "class": "市"
   },
   {
      "id": "MzUwNTAw",
      "label": "泉州市",
      "value": "350500",
      "class": "市"
   },
   {
      "id": "MzUwNjAw",
      "label": "漳州市",
      "value": "350600",
      "class": "市"
   },
   {
      "id": "MzUwNzAw",
      "label": "南平市",
      "value": "350700",
      "class": "市"
   },
   {
      "id": "MzUwODAw",
      "label": "龙岩市",
      "value": "350800",
      "class": "市"
   },
   {
      "id": "MzUwOTAw",
      "label": "宁德市",
      "value": "350900",
      "class": "市"
   }]
},
{
   "id": "MzYwMDAw",
   "label": "江西省",
   "value": "360000",
   "class": "省",
   "children": [
   {
      "id": "MzYwMTAw",
      "label": "南昌市",
      "value": "360100",
      "class": "市"
   },
   {
      "id": "MzYwMjAw",
      "label": "景德镇市",
      "value": "360200",
      "class": "市"
   },
   {
      "id": "MzYwMzAw",
      "label": "萍乡市",
      "value": "360300",
      "class": "市"
   },
   {
      "id": "MzYwNDAw",
      "label": "九江市",
      "value": "360400",
      "class": "市"
   },
   {
      "id": "MzYwNTAw",
      "label": "新余市",
      "value": "360500",
      "class": "市"
   },
   {
      "id": "MzYwNjAw",
      "label": "鹰潭市",
      "value": "360600",
      "class": "市"
   },
   {
      "id": "MzYwNzAw",
      "label": "赣州市",
      "value": "360700",
      "class": "市"
   },
   {
      "id": "MzYwODAw",
      "label": "吉安市",
      "value": "360800",
      "class": "市"
   },
   {
      "id": "MzYwOTAw",
      "label": "宜春市",
      "value": "360900",
      "class": "市"
   },
   {
      "id": "MzYxMDAw",
      "label": "抚州市",
      "value": "361000",
      "class": "市"
   },
   {
      "id": "MzYxMTAw",
      "label": "上饶市",
      "value": "361100",
      "class": "市"
   }]
},
{
   "id": "MzcwMDAw",
   "label": "山东省",
   "value": "370000",
   "class": "省",
   "children": [
   {
      "id": "MzcwMTAw",
      "label": "济南市",
      "value": "370100",
      "class": "市"
   },
   {
      "id": "MzcwMjAw",
      "label": "青岛市",
      "value": "370200",
      "class": "市"
   },
   {
      "id": "MzcwMzAw",
      "label": "淄博市",
      "value": "370300",
      "class": "市"
   },
   {
      "id": "MzcwNDAw",
      "label": "枣庄市",
      "value": "370400",
      "class": "市"
   },
   {
      "id": "MzcwNTAw",
      "label": "东营市",
      "value": "370500",
      "class": "市"
   },
   {
      "id": "MzcwNjAw",
      "label": "烟台市",
      "value": "370600",
      "class": "市"
   },
   {
      "id": "MzcwNzAw",
      "label": "潍坊市",
      "value": "370700",
      "class": "市"
   },
   {
      "id": "MzcwODAw",
      "label": "济宁市",
      "value": "370800",
      "class": "市"
   },
   {
      "id": "MzcwOTAw",
      "label": "泰安市",
      "value": "370900",
      "class": "市"
   },
   {
      "id": "MzcxMDAw",
      "label": "威海市",
      "value": "371000",
      "class": "市"
   },
   {
      "id": "MzcxMTAw",
      "label": "日照市",
      "value": "371100",
      "class": "市"
   },
   {
      "id": "MzcxMzAw",
      "label": "临沂市",
      "value": "371300",
      "class": "市"
   },
   {
      "id": "MzcxNDAw",
      "label": "德州市",
      "value": "371400",
      "class": "市"
   },
   {
      "id": "MzcxNTAw",
      "label": "聊城市",
      "value": "371500",
      "class": "市"
   },
   {
      "id": "MzcxNjAw",
      "label": "滨州市",
      "value": "371600",
      "class": "市"
   },
   {
      "id": "MzcxNzAw",
      "label": "菏泽市",
      "value": "371700",
      "class": "市"
   }]
},
{
   "id": "NDEwMDAw",
   "label": "河南省",
   "value": "410000",
   "class": "省",
   "children": [
   {
      "id": "NDE5MDAx",
      "label": "济源市",
      "value": "419001",
      "class": "市"
   },
   {
      "id": "NDEwMTAw",
      "label": "郑州市",
      "value": "410100",
      "class": "市"
   },
   {
      "id": "NDEwMjAw",
      "label": "开封市",
      "value": "410200",
      "class": "市"
   },
   {
      "id": "NDEwMzAw",
      "label": "洛阳市",
      "value": "410300",
      "class": "市"
   },
   {
      "id": "NDEwNDAw",
      "label": "平顶山市",
      "value": "410400",
      "class": "市"
   },
   {
      "id": "NDEwNTAw",
      "label": "安阳市",
      "value": "410500",
      "class": "市"
   },
   {
      "id": "NDEwNjAw",
      "label": "鹤壁市",
      "value": "410600",
      "class": "市"
   },
   {
      "id": "NDEwNzAw",
      "label": "新乡市",
      "value": "410700",
      "class": "市"
   },
   {
      "id": "NDEwODAw",
      "label": "焦作市",
      "value": "410800",
      "class": "市"
   },
   {
      "id": "NDEwOTAw",
      "label": "濮阳市",
      "value": "410900",
      "class": "市"
   },
   {
      "id": "NDExMDAw",
      "label": "许昌市",
      "value": "411000",
      "class": "市"
   },
   {
      "id": "NDExMTAw",
      "label": "漯河市",
      "value": "411100",
      "class": "市"
   },
   {
      "id": "NDExMjAw",
      "label": "三门峡市",
      "value": "411200",
      "class": "市"
   },
   {
      "id": "NDExMzAw",
      "label": "南阳市",
      "value": "411300",
      "class": "市"
   },
   {
      "id": "NDExNDAw",
      "label": "商丘市",
      "value": "411400",
      "class": "市"
   },
   {
      "id": "NDExNTAw",
      "label": "信阳市",
      "value": "411500",
      "class": "市"
   },
   {
      "id": "NDExNjAw",
      "label": "周口市",
      "value": "411600",
      "class": "市"
   },
   {
      "id": "NDExNzAw",
      "label": "驻马店市",
      "value": "411700",
      "class": "市"
   }]
},
{
   "id": "NDIwMDAw",
   "label": "湖北省",
   "value": "420000",
   "class": "省",
   "children": [
   {
      "id": "NDI5MDA0",
      "label": "仙桃市",
      "value": "429004",
      "class": "市"
   },
   {
      "id": "NDI5MDA1",
      "label": "潜江市",
      "value": "429005",
      "class": "市"
   },
   {
      "id": "NDI5MDA2",
      "label": "天门市",
      "value": "429006",
      "class": "市"
   },
   {
      "id": "NDI5MDIx",
      "label": "神农架林区",
      "value": "429021",
      "class": "市"
   },
   {
      "id": "NDIwMTAw",
      "label": "武汉市",
      "value": "420100",
      "class": "市"
   },
   {
      "id": "NDIwMjAw",
      "label": "黄石市",
      "value": "420200",
      "class": "市"
   },
   {
      "id": "NDIwMzAw",
      "label": "十堰市",
      "value": "420300",
      "class": "市"
   },
   {
      "id": "NDIwNTAw",
      "label": "宜昌市",
      "value": "420500",
      "class": "市"
   },
   {
      "id": "NDIwNjAw",
      "label": "襄阳市",
      "value": "420600",
      "class": "市"
   },
   {
      "id": "NDIwNzAw",
      "label": "鄂州市",
      "value": "420700",
      "class": "市"
   },
   {
      "id": "NDIwODAw",
      "label": "荆门市",
      "value": "420800",
      "class": "市"
   },
   {
      "id": "NDIwOTAw",
      "label": "孝感市",
      "value": "420900",
      "class": "市"
   },
   {
      "id": "NDIxMDAw",
      "label": "荆州市",
      "value": "421000",
      "class": "市"
   },
   {
      "id": "NDIxMTAw",
      "label": "黄冈市",
      "value": "421100",
      "class": "市"
   },
   {
      "id": "NDIxMjAw",
      "label": "咸宁市",
      "value": "421200",
      "class": "市"
   },
   {
      "id": "NDIxMzAw",
      "label": "随州市",
      "value": "421300",
      "class": "市"
   },
   {
      "id": "NDIyODAw",
      "label": "恩施土家族苗族自治州",
      "value": "422800",
      "class": "市"
   }]
},
{
   "id": "NDMwMDAw",
   "label": "湖南省",
   "value": "430000",
   "class": "省",
   "children": [
   {
      "id": "NDMwMTAw",
      "label": "长沙市",
      "value": "430100",
      "class": "市"
   },
   {
      "id": "NDMwMjAw",
      "label": "株洲市",
      "value": "430200",
      "class": "市"
   },
   {
      "id": "NDMwMzAw",
      "label": "湘潭市",
      "value": "430300",
      "class": "市"
   },
   {
      "id": "NDMwNDAw",
      "label": "衡阳市",
      "value": "430400",
      "class": "市"
   },
   {
      "id": "NDMwNTAw",
      "label": "邵阳市",
      "value": "430500",
      "class": "市"
   },
   {
      "id": "NDMwNjAw",
      "label": "岳阳市",
      "value": "430600",
      "class": "市"
   },
   {
      "id": "NDMwNzAw",
      "label": "常德市",
      "value": "430700",
      "class": "市"
   },
   {
      "id": "NDMwODAw",
      "label": "张家界市",
      "value": "430800",
      "class": "市"
   },
   {
      "id": "NDMwOTAw",
      "label": "益阳市",
      "value": "430900",
      "class": "市"
   },
   {
      "id": "NDMxMDAw",
      "label": "郴州市",
      "value": "431000",
      "class": "市"
   },
   {
      "id": "NDMxMTAw",
      "label": "永州市",
      "value": "431100",
      "class": "市"
   },
   {
      "id": "NDMxMjAw",
      "label": "怀化市",
      "value": "431200",
      "class": "市"
   },
   {
      "id": "NDMxMzAw",
      "label": "娄底市",
      "value": "431300",
      "class": "市"
   },
   {
      "id": "NDMzMTAw",
      "label": "湘西土家族苗族自治州",
      "value": "433100",
      "class": "市"
   }]
},
{
   "id": "NDQwMDAw",
   "label": "广东省",
   "value": "440000",
   "class": "省",
   "children": [
   {
      "id": "NDQ1MTAw",
      "label": "潮州市",
      "value": "445100",
      "class": "市"
   },
   {
      "id": "NDQ1MjAw",
      "label": "揭阳市",
      "value": "445200",
      "class": "市"
   },
   {
      "id": "NDQ1MzAw",
      "label": "云浮市",
      "value": "445300",
      "class": "市"
   },
   {
      "id": "NDQwMTAw",
      "label": "广州市",
      "value": "440100",
      "class": "市"
   },
   {
      "id": "NDQwMjAw",
      "label": "韶关市",
      "value": "440200",
      "class": "市"
   },
   {
      "id": "NDQwMzAw",
      "label": "深圳市",
      "value": "440300",
      "class": "市"
   },
   {
      "id": "NDQwNDAw",
      "label": "珠海市",
      "value": "440400",
      "class": "市"
   },
   {
      "id": "NDQwNTAw",
      "label": "汕头市",
      "value": "440500",
      "class": "市"
   },
   {
      "id": "NDQwNjAw",
      "label": "佛山市",
      "value": "440600",
      "class": "市"
   },
   {
      "id": "NDQwNzAw",
      "label": "江门市",
      "value": "440700",
      "class": "市"
   },
   {
      "id": "NDQwODAw",
      "label": "湛江市",
      "value": "440800",
      "class": "市"
   },
   {
      "id": "NDQwOTAw",
      "label": "茂名市",
      "value": "440900",
      "class": "市"
   },
   {
      "id": "NDQxMjAw",
      "label": "肇庆市",
      "value": "441200",
      "class": "市"
   },
   {
      "id": "NDQxMzAw",
      "label": "惠州市",
      "value": "441300",
      "class": "市"
   },
   {
      "id": "NDQxNDAw",
      "label": "梅州市",
      "value": "441400",
      "class": "市"
   },
   {
      "id": "NDQxNTAw",
      "label": "汕尾市",
      "value": "441500",
      "class": "市"
   },
   {
      "id": "NDQxNjAw",
      "label": "河源市",
      "value": "441600",
      "class": "市"
   },
   {
      "id": "NDQxNzAw",
      "label": "阳江市",
      "value": "441700",
      "class": "市"
   },
   {
      "id": "NDQxODAw",
      "label": "清远市",
      "value": "441800",
      "class": "市"
   },
   {
      "id": "NDQxOTAw",
      "label": "东莞市",
      "value": "441900",
      "class": "市"
   },
   {
      "id": "NDQyMDAw",
      "label": "中山市",
      "value": "442000",
      "class": "市"
   }]
},
{
   "id": "NDUwMDAw",
   "label": "广西壮族自治区",
   "value": "450000",
   "class": "省",
   "children": [
   {
      "id": "NDUwMTAw",
      "label": "南宁市",
      "value": "450100",
      "class": "市"
   },
   {
      "id": "NDUwMjAw",
      "label": "柳州市",
      "value": "450200",
      "class": "市"
   },
   {
      "id": "NDUwMzAw",
      "label": "桂林市",
      "value": "450300",
      "class": "市"
   },
   {
      "id": "NDUwNDAw",
      "label": "梧州市",
      "value": "450400",
      "class": "市"
   },
   {
      "id": "NDUwNTAw",
      "label": "北海市",
      "value": "450500",
      "class": "市"
   },
   {
      "id": "NDUwNjAw",
      "label": "防城港市",
      "value": "450600",
      "class": "市"
   },
   {
      "id": "NDUwNzAw",
      "label": "钦州市",
      "value": "450700",
      "class": "市"
   },
   {
      "id": "NDUwODAw",
      "label": "贵港市",
      "value": "450800",
      "class": "市"
   },
   {
      "id": "NDUwOTAw",
      "label": "玉林市",
      "value": "450900",
      "class": "市"
   },
   {
      "id": "NDUxMDAw",
      "label": "百色市",
      "value": "451000",
      "class": "市"
   },
   {
      "id": "NDUxMTAw",
      "label": "贺州市",
      "value": "451100",
      "class": "市"
   },
   {
      "id": "NDUxMjAw",
      "label": "河池市",
      "value": "451200",
      "class": "市"
   },
   {
      "id": "NDUxMzAw",
      "label": "来宾市",
      "value": "451300",
      "class": "市"
   },
   {
      "id": "NDUxNDAw",
      "label": "崇左市",
      "value": "451400",
      "class": "市"
   }]
},
{
   "id": "NDYwMDAw",
   "label": "海南省",
   "value": "460000",
   "class": "省",
   "children": [
   {
      "id": "NDY5MDA1",
      "label": "文昌市",
      "value": "469005",
      "class": "市"
   },
   {
      "id": "NDY5MDA2",
      "label": "万宁市",
      "value": "469006",
      "class": "市"
   },
   {
      "id": "NDY5MDA3",
      "label": "东方市",
      "value": "469007",
      "class": "市"
   },
   {
      "id": "NDY5MDAx",
      "label": "五指山市",
      "value": "469001",
      "class": "市"
   },
   {
      "id": "NDY5MDAy",
      "label": "琼海市",
      "value": "469002",
      "class": "市"
   },
   {
      "id": "NDY5MDI0",
      "label": "临高县",
      "value": "469024",
      "class": "市"
   },
   {
      "id": "NDY5MDI1",
      "label": "白沙黎族自治县",
      "value": "469025",
      "class": "市"
   },
   {
      "id": "NDY5MDI2",
      "label": "昌江黎族自治县",
      "value": "469026",
      "class": "市"
   },
   {
      "id": "NDY5MDI3",
      "label": "乐东黎族自治县",
      "value": "469027",
      "class": "市"
   },
   {
      "id": "NDY5MDI4",
      "label": "陵水黎族自治县",
      "value": "469028",
      "class": "市"
   },
   {
      "id": "NDY5MDI5",
      "label": "保亭黎族苗族自治县",
      "value": "469029",
      "class": "市"
   },
   {
      "id": "NDY5MDIx",
      "label": "定安县",
      "value": "469021",
      "class": "市"
   },
   {
      "id": "NDY5MDIy",
      "label": "屯昌县",
      "value": "469022",
      "class": "市"
   },
   {
      "id": "NDY5MDIz",
      "label": "澄迈县",
      "value": "469023",
      "class": "市"
   },
   {
      "id": "NDY5MDMw",
      "label": "琼中黎族苗族自治县",
      "value": "469030",
      "class": "市"
   },
   {
      "id": "NDYwMTAw",
      "label": "海口市",
      "value": "460100",
      "class": "市"
   },
   {
      "id": "NDYwMjAw",
      "label": "三亚市",
      "value": "460200",
      "class": "市"
   },
   {
      "id": "NDYwMzAw",
      "label": "三沙市",
      "value": "460300",
      "class": "市"
   },
   {
      "id": "NDYwNDAw",
      "label": "儋州市",
      "value": "460400",
      "class": "市"
   }]
},
{
   "id": "NTAwMDAw",
   "label": "重庆市",
   "value": "500000",
   "class": "省",
   "children": [
   {
      "id": "NTAwMTAw",
      "label": "重庆市",
      "value": "500100",
      "class": "市"
   },
   {
      "id": "NTAwMjAw",
      "label": "重庆郊县",
      "value": "500200",
      "class": "市"
   }]
},
{
   "id": "NTEwMDAw",
   "label": "四川省",
   "value": "510000",
   "class": "省",
   "children": [
   {
      "id": "NTEwMTAw",
      "label": "成都市",
      "value": "510100",
      "class": "市"
   },
   {
      "id": "NTEwMzAw",
      "label": "自贡市",
      "value": "510300",
      "class": "市"
   },
   {
      "id": "NTEwNDAw",
      "label": "攀枝花市",
      "value": "510400",
      "class": "市"
   },
   {
      "id": "NTEwNTAw",
      "label": "泸州市",
      "value": "510500",
      "class": "市"
   },
   {
      "id": "NTEwNjAw",
      "label": "德阳市",
      "value": "510600",
      "class": "市"
   },
   {
      "id": "NTEwNzAw",
      "label": "绵阳市",
      "value": "510700",
      "class": "市"
   },
   {
      "id": "NTEwODAw",
      "label": "广元市",
      "value": "510800",
      "class": "市"
   },
   {
      "id": "NTEwOTAw",
      "label": "遂宁市",
      "value": "510900",
      "class": "市"
   },
   {
      "id": "NTExMDAw",
      "label": "内江市",
      "value": "511000",
      "class": "市"
   },
   {
      "id": "NTExMTAw",
      "label": "乐山市",
      "value": "511100",
      "class": "市"
   },
   {
      "id": "NTExMzAw",
      "label": "南充市",
      "value": "511300",
      "class": "市"
   },
   {
      "id": "NTExNDAw",
      "label": "眉山市",
      "value": "511400",
      "class": "市"
   },
   {
      "id": "NTExNTAw",
      "label": "宜宾市",
      "value": "511500",
      "class": "市"
   },
   {
      "id": "NTExNjAw",
      "label": "广安市",
      "value": "511600",
      "class": "市"
   },
   {
      "id": "NTExNzAw",
      "label": "达州市",
      "value": "511700",
      "class": "市"
   },
   {
      "id": "NTExODAw",
      "label": "雅安市",
      "value": "511800",
      "class": "市"
   },
   {
      "id": "NTExOTAw",
      "label": "巴中市",
      "value": "511900",
      "class": "市"
   },
   {
      "id": "NTEyMDAw",
      "label": "资阳市",
      "value": "512000",
      "class": "市"
   },
   {
      "id": "NTEzMjAw",
      "label": "阿坝藏族羌族自治州",
      "value": "513200",
      "class": "市"
   },
   {
      "id": "NTEzMzAw",
      "label": "甘孜藏族自治州",
      "value": "513300",
      "class": "市"
   },
   {
      "id": "NTEzNDAw",
      "label": "凉山彝族自治州",
      "value": "513400",
      "class": "市"
   }]
},
{
   "id": "NTIwMDAw",
   "label": "贵州省",
   "value": "520000",
   "class": "省",
   "children": [
   {
      "id": "NTIwMTAw",
      "label": "贵阳市",
      "value": "520100",
      "class": "市"
   },
   {
      "id": "NTIwMjAw",
      "label": "六盘水市",
      "value": "520200",
      "class": "市"
   },
   {
      "id": "NTIwMzAw",
      "label": "遵义市",
      "value": "520300",
      "class": "市"
   },
   {
      "id": "NTIwNDAw",
      "label": "安顺市",
      "value": "520400",
      "class": "市"
   },
   {
      "id": "NTIwNTAw",
      "label": "毕节市",
      "value": "520500",
      "class": "市"
   },
   {
      "id": "NTIwNjAw",
      "label": "铜仁市",
      "value": "520600",
      "class": "市"
   },
   {
      "id": "NTIyMzAw",
      "label": "黔西南布依族苗族自治州",
      "value": "522300",
      "class": "市"
   },
   {
      "id": "NTIyNjAw",
      "label": "黔东南苗族侗族自治州",
      "value": "522600",
      "class": "市"
   },
   {
      "id": "NTIyNzAw",
      "label": "黔南布依族苗族自治州",
      "value": "522700",
      "class": "市"
   }]
},
{
   "id": "NTMwMDAw",
   "label": "云南省",
   "value": "530000",
   "class": "省",
   "children": [
   {
      "id": "NTMwMTAw",
      "label": "昆明市",
      "value": "530100",
      "class": "市"
   },
   {
      "id": "NTMwMzAw",
      "label": "曲靖市",
      "value": "530300",
      "class": "市"
   },
   {
      "id": "NTMwNDAw",
      "label": "玉溪市",
      "value": "530400",
      "class": "市"
   },
   {
      "id": "NTMwNTAw",
      "label": "保山市",
      "value": "530500",
      "class": "市"
   },
   {
      "id": "NTMwNjAw",
      "label": "昭通市",
      "value": "530600",
      "class": "市"
   },
   {
      "id": "NTMwNzAw",
      "label": "丽江市",
      "value": "530700",
      "class": "市"
   },
   {
      "id": "NTMwODAw",
      "label": "普洱市",
      "value": "530800",
      "class": "市"
   },
   {
      "id": "NTMwOTAw",
      "label": "临沧市",
      "value": "530900",
      "class": "市"
   },
   {
      "id": "NTMyMzAw",
      "label": "楚雄彝族自治州",
      "value": "532300",
      "class": "市"
   },
   {
      "id": "NTMyNTAw",
      "label": "红河哈尼族彝族自治州",
      "value": "532500",
      "class": "市"
   },
   {
      "id": "NTMyNjAw",
      "label": "文山壮族苗族自治州",
      "value": "532600",
      "class": "市"
   },
   {
      "id": "NTMyODAw",
      "label": "西双版纳傣族自治州",
      "value": "532800",
      "class": "市"
   },
   {
      "id": "NTMyOTAw",
      "label": "大理白族自治州",
      "value": "532900",
      "class": "市"
   },
   {
      "id": "NTMzMTAw",
      "label": "德宏傣族景颇族自治州",
      "value": "533100",
      "class": "市"
   },
   {
      "id": "NTMzMzAw",
      "label": "怒江傈僳族自治州",
      "value": "533300",
      "class": "市"
   },
   {
      "id": "NTMzNDAw",
      "label": "迪庆藏族自治州",
      "value": "533400",
      "class": "市"
   }]
},
{
   "id": "NTQwMDAw",
   "label": "西藏自治区",
   "value": "540000",
   "class": "省",
   "children": [
   {
      "id": "NTQwMTAw",
      "label": "拉萨市",
      "value": "540100",
      "class": "市"
   },
   {
      "id": "NTQwMjAw",
      "label": "日喀则市",
      "value": "540200",
      "class": "市"
   },
   {
      "id": "NTQwMzAw",
      "label": "昌都市",
      "value": "540300",
      "class": "市"
   },
   {
      "id": "NTQwNDAw",
      "label": "林芝市",
      "value": "540400",
      "class": "市"
   },
   {
      "id": "NTQwNTAw",
      "label": "山南市",
      "value": "540500",
      "class": "市"
   },
   {
      "id": "NTQwNjAw",
      "label": "那曲市",
      "value": "540600",
      "class": "市"
   },
   {
      "id": "NTQyNTAw",
      "label": "阿里地区",
      "value": "542500",
      "class": "市"
   }]
},
{
   "id": "NjEwMDAw",
   "label": "陕西省",
   "value": "610000",
   "class": "省",
   "children": [
   {
      "id": "NjEwMTAw",
      "label": "西安市",
      "value": "610100",
      "class": "市"
   },
   {
      "id": "NjEwMjAw",
      "label": "铜川市",
      "value": "610200",
      "class": "市"
   },
   {
      "id": "NjEwMzAw",
      "label": "宝鸡市",
      "value": "610300",
      "class": "市"
   },
   {
      "id": "NjEwNDAw",
      "label": "咸阳市",
      "value": "610400",
      "class": "市"
   },
   {
      "id": "NjEwNTAw",
      "label": "渭南市",
      "value": "610500",
      "class": "市"
   },
   {
      "id": "NjEwNjAw",
      "label": "延安市",
      "value": "610600",
      "class": "市"
   },
   {
      "id": "NjEwNzAw",
      "label": "汉中市",
      "value": "610700",
      "class": "市"
   },
   {
      "id": "NjEwODAw",
      "label": "榆林市",
      "value": "610800",
      "class": "市"
   },
   {
      "id": "NjEwOTAw",
      "label": "安康市",
      "value": "610900",
      "class": "市"
   },
   {
      "id": "NjExMDAw",
      "label": "商洛市",
      "value": "611000",
      "class": "市"
   }]
},
{
   "id": "NjIwMDAw",
   "label": "甘肃省",
   "value": "620000",
   "class": "省",
   "children": [
   {
      "id": "NjIwMTAw",
      "label": "兰州市",
      "value": "620100",
      "class": "市"
   },
   {
      "id": "NjIwMjAw",
      "label": "嘉峪关市",
      "value": "620200",
      "class": "市"
   },
   {
      "id": "NjIwMzAw",
      "label": "金昌市",
      "value": "620300",
      "class": "市"
   },
   {
      "id": "NjIwNDAw",
      "label": "白银市",
      "value": "620400",
      "class": "市"
   },
   {
      "id": "NjIwNTAw",
      "label": "天水市",
      "value": "620500",
      "class": "市"
   },
   {
      "id": "NjIwNjAw",
      "label": "武威市",
      "value": "620600",
      "class": "市"
   },
   {
      "id": "NjIwNzAw",
      "label": "张掖市",
      "value": "620700",
      "class": "市"
   },
   {
      "id": "NjIwODAw",
      "label": "平凉市",
      "value": "620800",
      "class": "市"
   },
   {
      "id": "NjIwOTAw",
      "label": "酒泉市",
      "value": "620900",
      "class": "市"
   },
   {
      "id": "NjIxMDAw",
      "label": "庆阳市",
      "value": "621000",
      "class": "市"
   },
   {
      "id": "NjIxMTAw",
      "label": "定西市",
      "value": "621100",
      "class": "市"
   },
   {
      "id": "NjIxMjAw",
      "label": "陇南市",
      "value": "621200",
      "class": "市"
   },
   {
      "id": "NjIyOTAw",
      "label": "临夏回族自治州",
      "value": "622900",
      "class": "市"
   },
   {
      "id": "NjIzMDAw",
      "label": "甘南藏族自治州",
      "value": "623000",
      "class": "市"
   }]
},
{
   "id": "NjMwMDAw",
   "label": "青海省",
   "value": "630000",
   "class": "省",
   "children": [
   {
      "id": "NjMwMTAw",
      "label": "西宁市",
      "value": "630100",
      "class": "市"
   },
   {
      "id": "NjMwMjAw",
      "label": "海东市",
      "value": "630200",
      "class": "市"
   },
   {
      "id": "NjMyMjAw",
      "label": "海北藏族自治州",
      "value": "632200",
      "class": "市"
   },
   {
      "id": "NjMyMzAw",
      "label": "黄南藏族自治州",
      "value": "632300",
      "class": "市"
   },
   {
      "id": "NjMyNTAw",
      "label": "海南藏族自治州",
      "value": "632500",
      "class": "市"
   },
   {
      "id": "NjMyNjAw",
      "label": "果洛藏族自治州",
      "value": "632600",
      "class": "市"
   },
   {
      "id": "NjMyNzAw",
      "label": "玉树藏族自治州",
      "value": "632700",
      "class": "市"
   },
   {
      "id": "NjMyODAw",
      "label": "海西蒙古族藏族自治州",
      "value": "632800",
      "class": "市"
   }]
},
{
   "id": "NjQwMDAw",
   "label": "宁夏回族自治区",
   "value": "640000",
   "class": "省",
   "children": [
   {
      "id": "NjQwMTAw",
      "label": "银川市",
      "value": "640100",
      "class": "市"
   },
   {
      "id": "NjQwMjAw",
      "label": "石嘴山市",
      "value": "640200",
      "class": "市"
   },
   {
      "id": "NjQwMzAw",
      "label": "吴忠市",
      "value": "640300",
      "class": "市"
   },
   {
      "id": "NjQwNDAw",
      "label": "固原市",
      "value": "640400",
      "class": "市"
   },
   {
      "id": "NjQwNTAw",
      "label": "中卫市",
      "value": "640500",
      "class": "市"
   }]
},
{
   "id": "NjUwMDAw",
   "label": "新疆维吾尔自治区",
   "value": "650000",
   "class": "省",
   "children": [
   {
      "id": "NjU0MDAw",
      "label": "伊犁哈萨克自治州",
      "value": "654000",
      "class": "市"
   },
   {
      "id": "NjU0MjAw",
      "label": "塔城地区",
      "value": "654200",
      "class": "市"
   },
   {
      "id": "NjU0MzAw",
      "label": "阿勒泰地区",
      "value": "654300",
      "class": "市"
   },
   {
      "id": "NjU5MDA0",
      "label": "五家渠市",
      "value": "659004",
      "class": "市"
   },
   {
      "id": "NjU5MDA1",
      "label": "北屯市",
      "value": "659005",
      "class": "市"
   },
   {
      "id": "NjU5MDA2",
      "label": "铁门关市",
      "value": "659006",
      "class": "市"
   },
   {
      "id": "NjU5MDA3",
      "label": "双河市",
      "value": "659007",
      "class": "市"
   },
   {
      "id": "NjU5MDA4",
      "label": "可克达拉市",
      "value": "659008",
      "class": "市"
   },
   {
      "id": "NjU5MDA5",
      "label": "昆玉市",
      "value": "659009",
      "class": "市"
   },
   {
      "id": "NjU5MDAx",
      "label": "石河子市",
      "value": "659001",
      "class": "市"
   },
   {
      "id": "NjU5MDAy",
      "label": "阿拉尔市",
      "value": "659002",
      "class": "市"
   },
   {
      "id": "NjU5MDAz",
      "label": "图木舒克市",
      "value": "659003",
      "class": "市"
   },
   {
      "id": "NjU5MDEw",
      "label": "胡杨河市",
      "value": "659010",
      "class": "市"
   },
   {
      "id": "NjUwMTAw",
      "label": "乌鲁木齐市",
      "value": "650100",
      "class": "市"
   },
   {
      "id": "NjUwMjAw",
      "label": "克拉玛依市",
      "value": "650200",
      "class": "市"
   },
   {
      "id": "NjUwNDAw",
      "label": "吐鲁番市",
      "value": "650400",
      "class": "市"
   },
   {
      "id": "NjUwNTAw",
      "label": "哈密市",
      "value": "650500",
      "class": "市"
   },
   {
      "id": "NjUyMzAw",
      "label": "昌吉回族自治州",
      "value": "652300",
      "class": "市"
   },
   {
      "id": "NjUyNzAw",
      "label": "博尔塔拉蒙古自治州",
      "value": "652700",
      "class": "市"
   },
   {
      "id": "NjUyODAw",
      "label": "巴音郭楞蒙古自治州",
      "value": "652800",
      "class": "市"
   },
   {
      "id": "NjUyOTAw",
      "label": "阿克苏地区",
      "value": "652900",
      "class": "市"
   },
   {
      "id": "NjUzMDAw",
      "label": "克孜勒苏柯尔克孜自治州",
      "value": "653000",
      "class": "市"
   },
   {
      "id": "NjUzMTAw",
      "label": "喀什地区",
      "value": "653100",
      "class": "市"
   },
   {
      "id": "NjUzMjAw",
      "label": "和田地区",
      "value": "653200",
      "class": "市"
   }]
},
{
   "id": "NzEwMDAw",
   "label": "台湾省",
   "value": "710000",
   "class": "省",
   "children": [
   {
      "id": "NzEwMTAw",
      "label": "台北市",
      "value": "710100",
      "class": "市"
   },
   {
      "id": "NzEwMzAw",
      "label": "基隆市",
      "value": "710300",
      "class": "市"
   },
   {
      "id": "NzEwODAw",
      "label": "新北市",
      "value": "710800",
      "class": "市"
   },
   {
      "id": "NzEwOTAw",
      "label": "桃园市",
      "value": "710900",
      "class": "市"
   },
   {
      "id": "NzEyMjAw",
      "label": "宜兰县",
      "value": "712200",
      "class": "市"
   }]
},
{
   "id": "ODEwMDAw",
   "label": "香港特别行政区",
   "value": "810000",
   "class": "省",
   "children": [

   ]
},
{
   "id": "ODIwMDAw",
   "label": "澳门特别行政区",
   "value": "820000",
   "class": "省",
   "children": [
   {
      "id": "ODIwMDA0",
      "label": "大堂区",
      "value": "820004",
      "class": "市"
   },
   {
      "id": "ODIwMDA1",
      "label": "风顺堂区",
      "value": "820005",
      "class": "市"
   },
   {
      "id": "ODIwMDA2",
      "label": "嘉模堂区",
      "value": "820006",
      "class": "市"
   },
   {
      "id": "ODIwMDA3",
      "label": "路凼填海区",
      "value": "820007",
      "class": "市"
   },
   {
      "id": "ODIwMDA4",
      "label": "圣方济各堂区",
      "value": "820008",
      "class": "市"
   },
   {
      "id": "ODIwMDAx",
      "label": "花地玛堂区",
      "value": "820001",
      "class": "市"
   },
   {
      "id": "ODIwMDAy",
      "label": "花王堂区",
      "value": "820002",
      "class": "市"
   },
   {
      "id": "ODIwMDAz",
      "label": "望德堂区",
      "value": "820003",
      "class": "市"
   }]
}]