<template>
   <n-upload :file-list="fileList" :action="host+'/industry/overviewConf/uploadChainImg'" :max="max" :data="{name:'file'}" :headers="reqUploadHeader" name="file" @change="uploadChange" @remove="uploadRemove" @finish="uploadFinish" :accept="accept" list-type="image-card"> </n-upload>
</template>
<script>
   import { onMounted, watch, ref, toRefs } from 'vue'
   import { token, config } from 'vapp2'
   import { cmdmessage } from '@/common'

   export default {
      name: 'rxp-upload2',
      emits: ['update:value'],
      props: {
         value: {
            type: Array,
            default () {
               return []
            }
         },
         options: {
            type: Object,
            default () {
               return {
                  imgWidth: 50,
                  imgHeight: 50,
                  imgFallback: '/assets/images/image.png',
                  label: '更新',
                  accept: null
               }
            }
         }
      },
      setup(props, context) {
         const { value } = toRefs(props)
         // 服务器地址
         const host = ref('')
         // 文件路径
         const fileList = ref([])
         // 线上文件地址
         const upres = {}
         // 上传的文件类型
         const accept = ref(props.options.accept || ".zip,.doc,.docx,.jpg,.png,.gif,.pdf,.xlsx,.xlsm,.xml,.xlsb,.csv,.json,.xls")
         // 上传数量
         const max = ref(props.options.max || 3)
         //上传请求头部信息
         const reqUploadHeader = ref({
            version: '1.0',
            get token() {
               return token.value
            },
            get timestamp() {
               return new Date().getTime()
            }
         })
         //  
         let iup = true
         const emitFileList = function () {
            iup = false
            let items = []
            fileList.value.forEach(element => {
               items.push({
                  id: element.id,
                  status: element.status,
                  name: element.name,
                  type: element.type,
                  percentage: element.percentage,
                  rid: upres[element.id].rowId || '',
                  fullPath: upres[element.id].filePath || ''
               })
            });
            context.emit('update:value', items)
         }
         const deleteFile = function (file) {
            if (file && file.id) {
               let index = fileList.value.findIndex(item => item.id == file.id)
               if (index > -1) {
                  fileList.value.splice(index, 1)
                  emitFileList()
               }
            }
         }
         const setFileData = function (file, data) {
            if (file && file.id) {
               let index = fileList.value.findIndex(item => item.id == file.id)
               if (index > -1) {
                  upres[file.id] = data
                  // fileList.value[index].fullPath = data.filePath
                  // fileList.value[index].id = data.rowId
                  // fileList.value[index].res = data
               }
            }
         }
         const uploadChange = function (data) {
            fileList.value = data.fileList
         }
         //上传完文件回调
         const uploadFinish = ({ file, event }) => {
            try {
               var res = JSON.parse(event.target.response)
               if (res.code != '2000') {
                  cmdmessage(res.message, 'error')
                  setTimeout(() => deleteFile(file), 0)
               } else {
                  setFileData(file, res.data)
                  emitFileList()
               }
            } catch (error) {
               throw error
            }
            return file;
         }
         //  手动删除
         const uploadRemove = () => setTimeout(() => emitFileList(), 0)
         // 图片地址更新
         watch(value, _val => {
            if (iup) {
               fileList.value = _val
            } else {
               iup = true
            }
         }, { immediate: true })

         //页面结构展示后处理
         onMounted(() => {
            host.value = config.network.host
         })
         return {
            max,
            accept,
            reqUploadHeader,
            host,
            fileList,
            uploadFinish,
            uploadRemove,
            uploadChange
         }
      },
   }
</script>
<style lang="less" scoped>
   .label {
      display: inline-block;
      padding: 0 5px;
   }
</style>