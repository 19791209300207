import notfound from './notfound.vue'
import redirect from './redirect.vue'
import { pagesPath } from '@/data'


export default function (apps) {
   let pageItems = [
      { path: '/', redirect: '/home' },
      { path: '/redirect/:path(.*)', component: redirect },
      { path: '/:pathMatch(.*)*', component: notfound }
   ]
   let paths = []
   let setChildrenPath = function (page) {
      if (Array.isArray(page.children)) {
         let children = []
         page.children.forEach(element => {
            let ename = page.name + "_" + element.name
            let epath = page.path + "/" + element.path
            let eparent = typeof element.parentName != 'undefined' ? page.ename + "_" + element.parentName : page.name
            let elabel = element.component.label || "?"
            paths.push({
               name: ename,
               path: epath,
               parent: eparent,
               label: elabel
            })
            element.name = ename
            children.push(element)
            if (element.children) {
               setChildrenPath(element)
            }
         })
         page.children = children
      }
   }
   for (const name in apps) {
      const pages = apps[name]
      if (Array.isArray(pages)) {
         pages.forEach(element => {
            let ename = name + "_" + element.name
            let epath = "/" + name + element.path
            let eparent = typeof element.parentName != 'undefined' ? name + "_" + element.parentName : null
            let elabel = element.component.label || "?"

            element.name = ename
            element.path = epath
            element.eparent = eparent

            paths.push({
               name: ename,
               path: epath,
               parent: eparent,
               label: elabel
            })
            if (element.children) {
               setChildrenPath(element)
            }
            pageItems.push(element)
         })
         pagesPath.value = paths
      }
   }
   return pageItems
}