import { h, resolveComponent } from 'vue'
import defImg from '@/assets/images/user_avatar.png'
import {
   NAvatar
} from 'naive-ui'
export default {
   name: 'rxp-avatar',
   components: { NAvatar },
   setup(props) {
      return () => {
         let _props = Object.assign({ fallbackSrc: defImg }, props)
         if (!_props.src) {
            _props.src = defImg
         }
         return h(resolveComponent('n-avatar'), _props)
      }
   }
}