import { pages, cmd } from 'vapp2'
import { ref, watch } from 'vue'

export const showLeft = ref(false)
export const showPath = ref(true)
export const isBanner = ref(false)

//隐藏左侧 菜单
const hideleft = ['notfound|^home',
  '^ucenter',
  '^policys',
  'informationDetails',   //资讯详情
  'quota',                //指标
  'enterprise_atlas',
  // 'mechanismDetails',      //机构 机构一览
  // 'mechanismMember',      //机构 团队成员
  // 'mechanismActivity',      //机构 相关动态
  // 'mechanismIncident',      //机构 投资事件

  // 'informationDetails',        //资讯详情
  'industry_nationalstandarddetail', // 国标准详情
  'industry_industrystandarddetail', // 行标准详情
]
const hidePath = ['home', 'policys_home']
const showBanner = ['^policys']

// 不需要菜单更新的页面名称
const disLoadChild = [
  '^(?=.*industry)(?!.*industry_industry$)',
  'enterprise_overview',
  'finance_mechanismDetails',
  'finance_mechanismMember',
  'finance_mechanismActivity',
  'finance_mechanismIncident',
  'finance_managingFunds',
  'finance_exitEvent',
  'enterprise_legalrisks',
  'enterprise_listinginfo',
  'enterprise_essentialinfo',
  'enterprise_businessmsg',
  'enterprise_businessrisks',
  'enterprise_intellectualright',
  // 'industry_enterpriseInfo',
  // 'industry_capitalInfo',
  // 'industry_financInfo',
  // 'industry_investmentInfo',
  // 'industry_knowInfo',
  // 'industry_patentInfo',
  // 'industry_industryMap',
  // 'industry_periphery',
  // 'industry_spaceDistribution',
  // 'industry_industrialFinancing',
  // 'industry_enterpriseInfo',
  // 'industry_industrialPolicy',
  // 'industry_industrialRadiation',
  // 'industry_detailPatent',
]

let appContent = null
let route = null

const handleFrame = function (newRoute) {
  showLeft.value = true
  showPath.value = true
  isBanner.value = false
  if (newRoute.matched && newRoute.matched.length > 0) {
    let name = newRoute.matched.map(item => item.name).join('.')
    if (name) {
      hideleft.forEach(element => {
        if (name && new RegExp(element).test(name)) {
          showLeft.value = false
        }
      })
      showBanner.forEach(element => {
        if (name && new RegExp(element).test(name)) {
          isBanner.value = true
        }
      })
    } else {
      showLeft.value = false
    }
    hidePath.forEach(element => {
      if (name && new RegExp(element).test(name)) {
        showPath.value = false
      }
    })
  }
}


export const initContent = function (_appContent) {
  appContent = _appContent
  route = pages.route

  watch(route, newRoute => {
    handleFrame(newRoute)
  }, { immediate: true })

}

export const isLoadMenus = function (name) {
  let load = true
  disLoadChild.forEach(element => {
    if (name && new RegExp(element).test(name)) {
      load = false
    }
  })
  return load
}