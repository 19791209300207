<template>
   <div class="eItem">
      <div class="label" :style="{width:labelWidth+'px'}" v-if="label">{{label}}</div>
      <div class="con">
         <slot></slot>
      </div>
   </div>
</template>
<script>
   export default {
      name: 'vx-item',
      props: {
         label: {
            type: String,
            default: ''
         },
         labelWidth: {
            type: Number,
            default: 88
         }
      },
      setup() {

      },
   }
</script>
<style lang="less" scoped>
   .eItem {
      display: flex;
      padding: 5px;

      .label {
         position: relative;
         width: 80px;
         min-width: 80px;
         text-align: right;
         display: flex;
         flex-direction: row-reverse;
         align-items: center;
         padding-right: 12px;
         color: #666666;

         &::after {
            content: ":";
            position: absolute;
            height: 100%;
            top: 0;
            right: 6px;
            display: flex;
            align-items: center;
         }
      }

      .con {
         flex: 1;
         overflow: auto;
      }
   }
</style>