<template>
   <div class="myrecycle">
      <Banner></Banner>
      <div class="box pmainContent">
         <div class="content">
            <h3>请填写需要转让的号码信息</h3>
            <n-form ref="form" label-placement="left" label-width="auto" class="form">
               <n-grid :cols="2" :x-gap="24">
                  <n-form-item-gi :span="2" label="号码">
                     <n-input v-model:value="formData.phone" placeholder="请输入要转让的号码" />
                  </n-form-item-gi>
                  <n-form-item-gi label="保底消费">
                     <n-input v-model:value="formData.prestore" placeholder="请输入您号码目前低消费金额" />
                  </n-form-item-gi>
                  <n-form-item-gi label="转让金额">
                     <n-input v-model:value="formData.price" placeholder="请输入您要转让的金额" />
                  </n-form-item-gi>
                  <n-form-item-gi :span="2" label="联系电话">
                     <n-input v-model:value="formData.tel" placeholder="请输入您的联系方式" />
                  </n-form-item-gi>
                  <n-form-item-gi :span="2" label="备注信息">
                     <n-input v-model:value="formData.remark" type="textarea" placeholder="其他需求说明情况" />
                  </n-form-item-gi>
                  <n-form-item-gi :span="2" label="">
                     <div class="subbtn">
                        <n-button type="info" @click="saveData">立即提交</n-button>
                     </div>
                  </n-form-item-gi>
               </n-grid>
            </n-form>
         </div>
      </div>
   </div>
</template>
<script>
   import { reactive, ref } from '@vue/reactivity'
   import Banner from './components/banner.vue'
   import { updata, cmd } from 'vapp2'
   import { cmdmessage } from '@/common'
   export default {
      name: "my-recycle",
      components: { Banner },
      setup() {
         const form = ref(null)
         const formData = reactive({ phone: null, tel: null, price: null, prestore: null, remark: null })

         return {
            form,
            formData,
            saveData() {
               updata.addRecycle(formData).then(res => {
                  cmd.execute('dialog', '信息已经提交成功,我们会及时与您联系。')
               })
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .myrecycle {
      .pmainContent {
         padding: 20px 40px;
         margin: 20px auto;

         h3 {
            font-size: 18px;
            margin-bottom: 30px;
         }

         .content {
            max-width: 800px;
            margin: auto;
            padding: 20px;
            border: 1px solid #f1f1f1;
         }
      }

      .subbtn {
         text-align: center;
         width: 100%;
      }
   }
</style>