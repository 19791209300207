<template>
   <div class="headerUser">
      <div class="vip" @click="collection">我的收藏</div>
   </div>
</template>
<script>
   import { ref } from 'vue'
   import { cmdmessage } from '@/common'
   import { NDropdown } from 'naive-ui'
   import { config, db, pages, cmd, token, updata } from 'vapp2'

   export default {
      name: 'header-user',
      components: { NDropdown },
      setup() {
         const userInfo = ref(db.user)
         const dropdownOptions = [
            { label: "个人信息", key: '/ucenter/uClientInfo', type: 'page' },
            { label: "退出账号", key: 'logout', type: 'modals' }
         ]
         const loginClick = function (val) {
            let row = { type: 'login' }
            cmd.execute('showLogin', row, null)
         }
         const registerClick = function (val) {
            let row = { type: 'register' }
            cmd.execute('showRegister', row, null)
         }
         if (token.isAuth.value && db.user) {
            updata.getClientInfoByClientId({ clientId: db.user.rowId }).then(res => {
               db.user = res.data
            })
         }
         cmd.add('updateUserInfo', function () {
            updata.getClientInfoByClientId({ clientId: db.user.rowId }).then(res => {
               userInfo.value = res.data
               db.user = res.data
            })
         })

         return {
            collection(){
               pages.go('/home/collection')
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .headerUser {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      padding: 0 12px;

      .vip {
         position: relative;
         display: inline-block;
         width: 120px;
         height: 36px;
         background: #FFBB59;
         border-radius: 18px;
         color: #ffffff;
         justify-content: center;
         align-items: center;
         display: inline-flex;
         cursor: pointer;

         &:hover {
            background: #db9a3f;
            color: #ffffff;
         }
      }

      .avt {
         margin-left: 12px;

         img {
            max-width: 100%;
         }
      }
   }
</style>