<template>
   <div class="headerNav">
      <div v-for="(menu,i) in menus" :key="i" class="menu" :class="{act:(currt&&currt.path==menu.path)}" @click="goPage(menu,i)">
         <div class="text">{{menu.label}}</div>
      </div>
   </div>
</template>
<script>
   import { config, pages } from 'vapp2'
   import { useRoute } from 'vue-router'

   export default {
      name: 'header-nav',
      setup() {
         const currt = useRoute()
         const menus = config.navs.menus
         return {
            menus,
            currt,
            reloadPage(fullPath) {
               pages.push({
                  path: '/redirect' + fullPath,
               })
            },
            goPage(menu) {
               pages.push({
                  path: menu.path
               })
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .headerNav {
      position: relative;
      display: flex;
      justify-content: end;
      flex: 1;

      .menu {
         position: relative;
         display: inline-flex;
         justify-content: center;
         align-items: center;
         height: 75px;
         padding: 0 10px;
         box-sizing: border-box;
         color: #666666;
         cursor: pointer;

         .text {
            display: inline-block;
            padding: 5px 6px;
            font-size: 14px;
            min-width: 80px;
            text-align: center;
         }

         &:hover,
         &.act {
            position: relative;
            color: #4E6AE7;

            &::after {
               content: "";
               position: absolute;
               box-sizing: border-box;
               bottom: 0;
               left: calc(50% - 26px);
               width: 52px;
               height: 3px;
               background: #4E6AE7;
               border-radius: 2px;
               z-index: -1;
            }
         }

         &.act {
            .text {
               font-weight: bold;
               font-size: 16px;
            }
         }
      }
   }
</style>