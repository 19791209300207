<template>
   <div class="homeSearch">
      <div class="banner">
         <n-carousel autoplay>
            <template v-for="item in items" :key="item.id">
               <div class="bitem" :style="{backgroundImage:'url('+network.host+'/../../public/'+item.image+')'}">
                  <h3>{{item.title}}</h3>
               </div>
            </template>
         </n-carousel>
      </div>
      <div class="searchForm">
         <div class="vx-flex search">
            <div class="vx-flex_item">
               <input v-model="keyword" class="input" placeholder="请输入想要查询的号码" />
            </div>
            <div>
               <button class="searchBtn" @click="searchData"><i class="tanshu-icon-83"></i> 查询</button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import { ref } from '@vue/reactivity'
   import { updata, config } from 'vapp2'
   export default {
      name: 'home-search',
      emits: ['search-keyword'],
      setup(props, context) {
         const keyword = ref("")
         const items = ref([])
         updata.getInfo({ type: '0' }).then(res => {
            items.value = res.data
         })
         return {
            keyword,
            items,
            network: config.network,
            searchData() {
               context.emit('search-keyword', keyword.value)
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .homeSearch {
      position: relative;
      height: 300px;
      color: #ffffff;

      .banner {
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;

         .bitem {
            position: relative;
            background-size: cover;
            text-align: center;
            height: 100%;
         }

         h3 {
            font-size: 32px;
            margin: 0;
            padding-top: 50px;
         }
      }


      .searchForm {
         position: absolute;
         width: 100%;
         height: calc(100% - 50px);
         top: 20px;
         left: 0;
         display: flex;
         -webkit-flex-direction: column;
         flex-direction: column;
         justify-content: center;
         align-items: center;
      }

      .search {
         position: relative;
         width: 600px;
         padding-bottom: 50px;
         margin-top: 100px;

         .input {
            height: 42px;
            background: #FFFFFF;
            border: 1px solid #283599;
            border-right: none;
            width: 100%;
            padding-left: 27px;
         }

         .searchBtn {
            width: 120px;
            height: 42px;
            background: #5978FF;
            border: 1px solid #283599;
            color: #FFFFFF;
            cursor: pointer;
            font-size: 18px;
            align-items: center;
            justify-content: center;
            display: flex;

            i {
               font-size: 22px;
               display: inline-block;
               margin-right: 12px;
            }

            &:hover {
               background-color: #3878FF;
            }
         }

      }
   }
</style>