import { create, updata, event, config, token, cmd } from 'vapp2'
import frame from './frame'
import handlePage from './frame/handlePage'
import created from './created'
import naive from 'naive-ui'
import directive from './directive'

// 应用
let app = null
token.keepSession = true

updata.addRes((res) => {
   if (res instanceof Blob) {
      return {
         code: config.state.code.ok,
         data: res
      }
   } else {
      if (res.code == "TE6000") {
         cmd.execute('showLogin')
      } else {
         return res
      }
   }
})

// 应用构建完成
event.on('app-created', created)

/**
 * 创建应用
 * @param {*} options 
 * @returns 
 */
export const createRxapp = function (options) {
   // 创建应用
   let DOM = options.dom || '#app'
   let myConfig = options.config || {}
   let pages = handlePage(options.pages)
   let plugin = Array.isArray(options.plugin) ? [naive, ...options.plugin, directive] : [naive, directive]
   let data = options.data || {}
   options.service && updata.assign(options.service)
   app = create(DOM, {
      config: myConfig,
      frame,
      pages,
      data,
      plugin,
      rOptions: {
         scrollBehavior() {
            // 始终滚动到顶部
            return { top: 0 }
            
         }
      }
   })

   return app
}