import { cmd, db, token, config } from 'vapp2'
import { ref, watch } from 'vue'
import menus from './menus'

export const menuTop = ref([])
export const pagesPath = ref([])
export const services = ref([
   { label: "电信", value: '电信' },
   { label: "移动", value: '移动' },
   { label: "联通", value: '联通' },
   { label: "虚商", value: '虚商' }
])

export const initData = function () {
   // 设置新菜单
   config.setNavs(JSON.parse(JSON.stringify(menus)), { keyName: 'path' })
   db.addDeep('collect')
   if (!db.collect.phone) {
      db.collect.phone = []
   }
}


cmd.add('logout', () => {
   token.clear()
   db.clear(true)
})

export default {
   localStore: ['user', 'systemInfo']
}