<template>
   <div class="tabs" :style="style">
      <div class="tab" :class="{act:i==index}" v-for="(item,i) in items" :key="i" @click.stop="$emit('click',item,i)">{{item.label||item}}</div>
   </div>
</template>
<script>
   export default {
      name: 'tabs',
      emits: ['click'],
      props: {
         index: {
            type: Number,
            default: 0
         },
         items: {
            type: Array,
            default () {
               return []
            }
         },
         style: {
            type: Object,
            default () {
               return {}
            }
         }
      },
      setup() {

      },
   }
</script>
<style lang="less" scoped>
   .tabs {
      position: relative;
      border-bottom: 1px solid #F0F0F0;

      .tab {
         position: relative;
         line-height: 52px;
         display: inline-block;
         font-size: 20px;
         color: #999999;
         padding: 0 6px;
         margin-right: 50px;
         cursor: pointer;

         &.act {
            color: #5978FF;

            &::after {
               content: "";
               position: absolute;
               bottom: 0;
               left: 0;
               width: 100%;
               height: 4px;
               background: #5978FF;
               border-radius: 2px;
            }
         }
      }
   }
</style>