<template>
   <div class="home">
      <HomeSearch @search-keyword="searchKeyword"></HomeSearch>
      <div class="homeMain">
         <HomeFilter @click-search="search"></HomeFilter>
         <HomeTable ref="reftable" :sale="0"></HomeTable>
      </div>
   </div>
</template>
<script>
   import HomeSearch from './components/homeSearch.vue'
   import HomeFilter from './components/homeFilter.vue'
   import HomeTable from './components/homeTable.vue'
   import { ref } from '@vue/reactivity'
   export default {
      name: 'home',
      label: '首页',
      components: { HomeSearch, HomeFilter, HomeTable },
      setup() {
         const reftable = ref(null)
         const search = function (val) {
            if (reftable.value) {
               reftable.value.loadData(val, true)
            }
         }
         const searchKeyword = function (keyword) {
            if (reftable.value) {
               reftable.value.loadData({ keyword }, true)
            }
         }
         return { reftable, search, searchKeyword }
      }
   }
</script>
<style lang="less" scoped>
   .home {
      position: relative;
      margin: auto;
      height: 100%;

      .homeMain {
         position: relative;
         margin: auto;
         max-width: 1280px;
      }

      .between {
         justify-content: space-between;
      }


   }
</style>