const myConfig = {
   system: {
      //    系统标识（英文字母）
      name: 'haoka520',
      //   系统名称
      title: '',
      //   版权信息
      copyright: ''
   },
   //    网络接口服务
   network: {
      //    主机地址
      get host() {
         return import.meta.env.VITE_APP_API
      },
      method: 'POST',
      //   令牌存储名称
      authKey: 'token',
      //   默认头部信息
      headers: {
         'Access-Control-Allow-Origin': '*',
         'Content-Type': 'application/json',
         'version': '1.0',
         get timestamp() {
            return new Date().getTime()
         }
      },
      //   返回数据类型
      responseType: 'json',
      mock: true
   },
   //    样式配置
   style: {},
   state: {
      // 接口请求状态码
      code: {
         //    成功
         ok: 0,
         //   找不到
         nothing: 404,
         //   令牌失效
         token: 'TE6000',
         // 错误
         error: 'error'
      }
   },
   //    框架配置
   frame: {
      main: 'main'
   },
   // 导航菜单信息
   navs: [
      { label: '首页', path: '/home' }
   ],
   // 导航菜单设置
   navsOptions: { keyName: 'path' },
   //    权限
   authority: []
}

export default myConfig