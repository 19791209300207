<template>
   <div class="box collectTable">
      <n-data-table :columns="columns" :data="data" :pagination="page" striped />
      <phoneDetail ref="detail"></phoneDetail>
   </div>
</template>
<script>
   import { ref, h, reactive, resolveComponent } from 'vue'
   import { updata, db } from 'vapp2'
   import PhoneDetail from './phoneDetail.vue'
   import PhoneNumber from './phoneNumber.vue'
   export default {
      name: 'collect-table',
      components: { PhoneDetail },
      setup(props, context) {
         const detail = ref(null)
         const data = ref(db.collect.phone)
         const page = reactive({
            page: 1,
            itemCount: 0,
            pageSize: 20
         })
         const columns = ref([
            {
               title: "手机号码",
               key: "phone",
               render(row) {
                  return h(PhoneNumber, {
                     value: row.phone,
                     onClick() {
                        detail.value.open(row)
                     }
                  })
               }
            },
            {
               title: "归属地",
               render(row) {
                  return h('div', row.province + row.city)
               }
            },
            { title: "运营商", key: "service" },
            { title: "售价", key: "money" },
            {
               title: '操作',
               width: 130,
               render(row) {
                  let btn = resolveComponent('n-button')
                  return h(btn, {
                     onClick(val) {
                        let index = db.collect.phone.findIndex(item => item.id == row.id)
                        if (index > -1) {
                           db.collect.phone.splice(index, 1)
                           data.value = JSON.parse(JSON.stringify(db.collect.phone))
                        }
                     }
                  }, { default: () => '删除' })
               }
            }
         ])
         return { detail, data, columns, page }
      },
   }
</script>
<style lang="less" scoped>
   .collectTable {
      position: relative;
      padding: 0;

      .pagination {
         padding: 20px;
      }
   }
</style>