<template>
   <n-upload :action="host+'/industry/overviewConf/uploadChainImg'" :headers="reqUploadHeader" name="file" @finish="uploadFinish" :accept="accept" :show-file-list="false">
      <n-button text>
         <rxp-image :width="options.imgWidth" :height="options.imgHeight" :src="imageVal" :fallback-src="options.imgFallback" preview-disabled />
         <span class="label" v-if="options.label">{{options.label}}</span>
      </n-button>
   </n-upload>
</template>
<script>
   import { onMounted, ref, toRefs, watch } from 'vue'
   import { token, config } from 'vapp2'
   import { cmdmessage } from '@/common'

   export default {
      name: 'rxp-upload',
      emits: ['update:value'],
      props: {
         value: {
            type: String,
            default: ''
         },
         options: {
            type: Object,
            default () {
               return {
                  imgWidth: 50,
                  imgHeight: 50,
                  imgFallback: '/assets/images/image.png',
                  label: '更新',
                  accept: null
               }
            }
         }
      },
      setup(props, context) {
         const { value } = toRefs(props)
         // 服务器地址
         const host = ref('')
         // 文件路径
         const imageVal = ref('')
         // 上传的文件类型
         const accept = ref(props.options.accept || ".zip,.doc,.docx,.jpg,.png,.gif,.pdf,.xlsx,.xlsm,.xml,.xlsb,.csv,.json,.xls")
         //上传请求头部信息
         const reqUploadHeader = ref({
            version: '1.0',
            get token() {
               return token.value
            },
            get timestamp() {
               return new Date().getTime()
            }
         })
         //上传完文件回调
         const uploadFinish = ({ file, event }) => {
            try {
               var res = JSON.parse(event.target.response)
               if (res.code == '2000') {
                  context.emit('update:value', res.data.filePath)
               } else {
                  cmdmessage(res.message, 'error')
               }
            } catch (error) {
               throw error
            }
            return file;
         };
         // 图片地址更新
         watch(value, _val => {
            imageVal.value = _val
         }, { immediate: true })
         //页面结构展示后处理
         onMounted(() => {
            host.value = config.network.host
         })
         return {
            accept,
            host,
            imageVal,
            uploadFinish,
            reqUploadHeader
         }
      },
   }
</script>
<style lang="less" scoped>
   .label {
      display: inline-block;
      padding: 0 5px;
   }
</style>