import { updata } from 'vapp2'
import { watch } from 'vue'

const createLoadingElement = function (arg) {
   let pieceLeft = "50%"
   let pieceTop = "0"
   if (arg) {
      pieceLeft = parseInt(arg) + "%"
      pieceTop = Math.round(arg % 10 * 100) + "%"
   }
   let loadNode = document.createElement("div")
   loadNode.style.position = "absolute"
   loadNode.style.left = "0"
   loadNode.style.top = "0"
   loadNode.style.width = "100%"
   loadNode.style.height = "100%"
   let piece = document.createElement("div")
   piece.style.position = "absolute"
   piece.style.left = pieceLeft
   piece.style.top = pieceTop
   piece.className = "loading"
   loadNode.appendChild(piece)
   return loadNode
}

const loadingRemove = function (el) {
   if (el.vloading.parentElement == el) {
      el.vloading.remove()
   }
   if (el.tt) {
      clearTimeout(el.tt)
      el.tt = null
   }
}

const setLoadNode = function (el, remove = false) {
   if (el.vloading && remove) {
      loadingRemove(el)
   } else if (el.vloading) {
      if (el.vloading.parentElement != el) {
         el.appendChild(el.vloading)
         el.tt = setTimeout(() => loadingRemove(el), 100000);
      }
   }
}

const setloading = function (el, value, arg) {
   if (el) {
      if (!el.vloading) {
         el.vloading = createLoadingElement(arg)
         if (getComputedStyle(el).position == 'static') {
            el.style.position = 'relative'
         }
      }
      if (typeof value == 'boolean') {
         if (value) {
            setLoadNode(el)
         } else if (el.vloading) {
            setLoadNode(el, true)
         }
      } else if (value && typeof value == 'string') {
         if (!el.stop) {
            el.stop = watch(updata.PendingItems, apis => {
               if (apis.includes(value)) {
                  setLoadNode(el)
               } else if (el.vloading) {
                  setLoadNode(el, true)
               }
            })
         }
      }
   }
}

export default {
   install(app) {
      app.directive('loading', {
         mounted(el, binding) {
            if (binding.value) {
               setloading(el, binding.value, binding.arg)
            }
         },
         updated(el, binding) {
            setloading(el, binding.value, binding.arg)
         },
         unmounted(el) {
            setLoadNode(el, true)
            delete el.vloading
            if (el.stop) {
               el.stop()
               delete el.stop
            }
         }
      })
   }
}