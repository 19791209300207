<template>
   <div class="vx-flex_item appMainContent" ref="appContent">
      <div class="vx-flex appMainContentCenter">
         <div class="vx-flex_item myPageContent">
            <router-view></router-view>
         </div>
      </div>
      <component v-for="(val,i) in modals" :is="val.component" :key="i"></component>
      <!-- <FloatBar class="float" @click="clickBar" /> -->
   </div>
</template>
<script>
   import { onMounted, provide, ref, watch } from '@vue/runtime-core'
   import modals from '@/modals'
   import { initContent } from '@/common/global'
   import AppFooter from './footer.vue'
   import { useRoute } from 'vue-router'
   import FloatBar from './floatBar.vue'
   export default {
      name: 'app-content',
      components: { AppFooter, FloatBar },
      setup() {
         const route = useRoute()
         const appContent = ref(null)
         const height = ref(0)
         const scrolltop = ref(0)
         const scrollBottom = ref(0)
         provide('height', height)
         provide('scrolltop', scrolltop)
         provide('scrollBottom', scrollBottom)
         initContent(appContent)

         watch(route, () => {
            if (appContent.value) {
               appContent.value.scrollTo({ top: 0 })
            }
         })
         onMounted(() => {
            height.value = appContent.value.offsetHeight
            window.addEventListener('resize', () => {
               height.value = appContent.value.offsetHeight
            })
            appContent.value.addEventListener('scroll', evt => {
               scrollBottom.value = evt.target.scrollHeight - evt.target.scrollTop - evt.target.getBoundingClientRect().height
               scrolltop.value = evt.target.scrollTop
            })
         })
         return {
            modals,
            appContent,
            clickBar() {
               appContent.value.scrollTo({ top: 0 })
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .appMainContent {
      position: relative;
      height: 100%;
      overflow: auto;

      .appMainContentCenter {
         margin: auto;

         &.banner {
            width: 100%;
         }
      }

      .float {
         position: fixed;
         right: calc((100% - 1440px)/2 - 66px);
         top: 350px;
      }


   }

   @media screen and (max-width: 1600px) {
      .appMainContent {
         .float {
            position: fixed;
            right: 10px;
            top: 350px;
         }
      }
   }

   .myPageContent {
      position: relative;
      min-height: 660px;
   }
</style>