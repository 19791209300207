<template>
   <div class="cardTitle">
      <div class="title">{{title}}</div>
      <div class="extra">
         <slot>
            <div class="moreButton hover" @click="clickToMap">更多 <i class="icon tanshufont jiantou"></i></div>
         </slot>
      </div>
   </div>
</template>
<script>
   export default {
      name: 'card-title',
      emits: ['click-more'],
      props: {
         title: {
            type: String,
            default: ''
         }
      },
      setup(props, context) {
         return {
            clickToMap() {
               context.emit('click-more')
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .cardTitle {
      display: flex;
      position: relative;
      width: 100%;
      border-bottom: 1px solid #EBEBEB;
      line-height: 68px;
      padding-left: 40px;
      padding-right: 40px;

      .title {
         position: relative;
         font-size: 20px;
         color: #111111;
         flex: 1;
      }



      .moreButton {
         position: relative;
         font-size: 16px;
         color: #4E6AE7;
      }
   }
</style>