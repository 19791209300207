<template>
   <n-modal v-model:show="show" transform-origin="center" to="#app" preset="card" :style="bodyStyleP0" :mask-closable="true" @after-leave="afterLeave" bordered>
      sdfsdfsf
   </n-modal>
</template>
<script>
   import { ref, defineComponent } from "vue"
   import { cmd, updata } from 'vapp2'
   export default defineComponent({
      name: 'show-login',
      setup(props, context) {
         const show = ref(false)
         let callBack = null
         const open = function (val, _callBack) {
            show.value = true
            callBack = _callBack
         }
         cmd.add('showLogin', open)
         const afterLeave = function () {
            callBack && callBack()
         }
         const closeView = function () {
            show.value = false;
         }
         return {
            show,
            closeView,
            afterLeave,
            bodyStyle: {
               width: '450px', //600  619
               height: '523px', //478   562
               background: '#FFFFFF',
               borderRadius: '8px',
               boxShadow: '0px 5px 5px 0px rgba(0, 21, 41, 0.08)'
            },
            bodyStyleP0: {
               width: '450px', //600  619
               height: '483px', //478   562
               background: '#FFFFFF',
               borderRadius: '8px',
               boxShadow: '0px 5px 5px 0px rgba(0, 21, 41, 0.08)'
            },
         }
      }
   })
</script>

<style lang="less" scoped>
   .assignList {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: var(--rd-content-bg-color);
      overflow: hidden;
   }

   .leftContain {
      position: relative;
      width: 290px;
      height: 100%;
   }


   .modalLX {
      position: relative;
      margin-left: 0px;
      margin-top: 0px;
      width: 100%;
      height: 50px; //59+39   59 

      .imgIcon {
         position: absolute;
         left: calc(50% - 104px);
         top: 25px; //39px
         height: 59px; //59+39   59 
         width: 208px;
      }
   }



   // .n-card > .n-card-header .n-card-header__main {
   //    :.n-card > .n-card-header {

   .loginCotain {
      margin-top: 15px;
   }
</style>