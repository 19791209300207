<template>
   <div class="banner">
      <n-carousel autoplay>
         <template v-for="item in items" :key="item.id">
            <div class="bitem" :style="{backgroundImage:'url('+network.host+'/../../public/'+item.image+')'}"> </div>
         </template>
      </n-carousel>
   </div>
</template>
<script>
   import { ref } from '@vue/reactivity'
   import { updata, config } from 'vapp2'
   export default {
      name: 'my-banner',
      setup() {

         const items = ref([])
         updata.getInfo({ type: '1' }).then(res => {
            items.value = res.data
         })
         return {
            items,
            network: config.network
         }
      },
   }
</script>
<style lang="less" scoped>
   .banner {
      position: relative;
      height: 300px;
      width: 100%;

      .bitem {
         position: relative;
         background-position: center;
         background-size: cover;
         text-align: center;
         height: 100%;
      }
   }
</style>