import home from './home.vue'
import sale from './sale.vue'
import diy from './diy.vue'
import recycle from './recycle.vue'
import cservice from './cservice.vue'
import business from './business.vue'
import collection from './collection.vue'
export default [
   { path: '', name: 'home', component: home },
   { path: '/diy', name: 'diy', component: diy, parentPath: 'home' },
   { path: '/sale', name: 'sale', component: sale, parentPath: 'home' },
   { path: '/recycle', name: 'recycle', component: recycle, parentPath: 'home' },
   { path: '/cservice', name: 'cservice', component: cservice, parentPath: 'home' },
   { path: '/business', name: 'business', component: business, parentPath: 'home' },
   { path: '/collection', name: 'collection', component: collection, parentPath: 'home' }
]