<template>
   <div class="footer" style="text-align: center">
      <div>Copyright © 2016 -2023  宁波靓之号电子商务有限公司 All Rights Reserved手机： 15657470116  网址： www.haoka520.cn 浙ICP备2022025714号 <img src="/assets/gongan2.png" height="20" /> 浙公网安备33021202002174号 </div>
   </div>
</template>
<script>
   export default {
      name: "app-footer",
      setup() {
         return {};
      },
   };
</script>
<style lang="less" scoped>
   .footer {
      position: relative;
      color: #383b48;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 0 6px rgba(138, 138, 138, 0.1);
      background-color: #ffffff;
      border-top: 1px solid #efefef;
      width: 100%;

      img {
         display: inline-block;
         vertical-align:text-bottom;
      }
   }
</style>