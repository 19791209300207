<template>
   <div class="appHeader">
      <div class="vx-flex con">
         <HeaderLogo />
         <HeaderNav />
         <HeaderUser />
      </div>
   </div>
</template>
<script>
   import { useMessage, useDialog } from 'naive-ui'
   import { cmd, event } from 'vapp2'
   import HeaderLogo from './headerLogo.vue'
   import HeaderNav from './headerNav.vue'
   import HeaderUser from './headerUser.vue'
   export default {
      name: 'app-header',
      components: { HeaderLogo, HeaderNav, HeaderUser },
      setup() {
         const message = useMessage()
         const dialog = useDialog()
         event.on('message', (content, type = 'info', option = {}) => {
            message[type] && message[type].call(message, content, option)
         })
         cmd.add('dialog', (content, type = 'success', option = { title: '成功' }) => {
            let myOption = Object.assign({ content }, option)
            dialog[type] && dialog[type].call(dialog, myOption)
         })
         return {}
      },
   }
</script>
<style lang="less" scoped>
   .appHeader {
      position: relative;
      width: 100%;
      z-index: 10;
      height: 75px;
      background-color: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(217, 231, 235, 0.44);

      .con {
         position: relative;
         margin: auto;
         max-width: 1400px;
      }
   }
</style>