<template>
   <div class="mydiy">
      <Banner></Banner>
      <div class="box pmainContent">
         <div class="content">
            <h3>请填写定制号码信息</h3>
            <n-form ref="form" label-placement="left" label-width="auto" class="form">
               <n-grid :cols="2" :x-gap="24">
                  <n-form-item-gi :span="2" label="定制信息">
                     <n-input v-model:value="formData.rule" placeholder="如尾部8888,中间8888,5656,AAAA+,AABB等" />
                  </n-form-item-gi>
                  <n-form-item-gi label="选择地区">
                     <n-space>
                        <n-select style="width:120px" v-model:value="formData.province" placeholder="请选择省份" :options="optionsProvince" key-field="value" cascade show-path />
                        <n-select style="width:120px" v-model:value="formData.city" placeholder="请选择城市" clearable :options="optionsCity" />
                     </n-space>
                  </n-form-item-gi>
                  <n-form-item-gi label="运营商">
                     <n-select v-model:value="formData.service" placeholder="选择运营商" :options="optionsServicer" />
                  </n-form-item-gi>
                  <n-form-item-gi :span="2" label="联系电话">
                     <n-input v-model:value="formData.tel" placeholder="请输入您的联系方式" />
                  </n-form-item-gi>
                  <n-form-item-gi :span="2" label="备注信息">
                     <n-input v-model:value="formData.remark" type="textarea" placeholder="其他需求说明情况" />
                  </n-form-item-gi>
                  <n-form-item-gi :span="2" label="">
                     <div class="subbtn">
                        <n-button type="info" @click="saveData">立即定制</n-button>
                     </div>
                  </n-form-item-gi>
               </n-grid>
            </n-form>
         </div>
      </div>
   </div>
</template>
<script>
   import { reactive, ref, computed } from 'vue'
   import Banner from './components/banner.vue'
   import { default as city, getOptions } from '@/data/city'
   import { services } from '@/data'
   import { updata, cmd } from 'vapp2'
   export default {
      name: "my-diy",
      components: { Banner },
      setup() {
         const form = ref(null)
         const formData = reactive({ rule: null, province: null, city: null, service: null, tel: null, remark: null })

         const optionsProvince = getOptions(city)
         const optionsCity = computed(() => {
            if (formData.province) {
               let index = optionsProvince.findIndex(item => item.value == formData.province)
               if (index > -1) {
                  return getOptions(city[index].children)
               } else {
                  return []
               }
            } else {
               return []
            }
         })
         return {
            form,
            formData,
            optionsProvince,
            optionsCity,
            optionsServicer: services,
            saveData() {
               let myData = { rule: formData.rule, area: formData.province + formData.city, service: formData.service, tel: formData.tel, remark: formData.remark }
               updata.addDiy(myData).then(res => {
                  cmd.execute('dialog', '信息已经提交成功,我们会及时与您联系。')
               })
            }
         }
      },
   }
</script>
<style lang="less" scoped>
   .mydiy {
      .pmainContent {
         padding: 20px 40px;
         margin: 20px auto;

         h3 {
            font-size: 18px;
            margin-bottom: 30px;
         }

         .content {
            max-width: 800px;
            margin: auto;
            padding: 20px;
            border: 1px solid #f1f1f1;
         }
      }

      .subbtn {
         text-align: center;
         width: 100%;
      }
   }
</style>