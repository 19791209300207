import { createRxapp } from './rxpapp'
import components from './components'
import pages from './pages'
import data from './data'
import service from './service'
import config from './config'
import './assets/css.less'
// 创建应用
createRxapp({
   config,
   plugin: [components],
   pages,
   data,
   service
})