import rxpAvatar from './rxpAvatar'
import rxpImage from './rxpImage'
import cardTitle from './cardTitle.vue'
import tabs from './tabs.vue'
import tlabel from './tlabel.vue'
import upload from './upload.vue'
import upload2 from './upload2.vue'
import radioButton from './radioButton.vue'
import item from './item.vue'

const coms = [rxpAvatar, rxpImage, upload, upload2, cardTitle, tabs, tlabel, radioButton, item]

export default {
   install(Vue) {
      coms.forEach(item => {
         Vue.component(item.name, item)
      })
   }
}