<template>
   <div class="myLabel">
      <div class="label">{{title}}</div>
      <slot></slot>
   </div>
</template>
<script>
   export default {
      name: 'tlabel',
      props: ['title'],
      setup() {},
   }
</script>
<style lang="less" scoped>
   .myLabel {
      display: inline-block;

      .label {
         display: inline-block;
         color: #333333;
      }

      color: #999999;
   }
</style>